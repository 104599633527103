import axios from "axios";
import { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import { Button, CardBody, CardFooter, CardHeader, CardTitle, FormGroup, Input, Label, Modal } from "reactstrap";
import { useTaxon } from "../../util/TaxonContext";

export default function FlagModal({ notify, flagModalOpen, setFlagModalOpen }) {
    const { taxon } = useTaxon(); // Access taxon and setTaxon from context
    const [flagContent, setFlagContent] = useState('');
    const [flagType, setFlagType] = useState('');

    const handleFlagCancel = () => {
        setFlagContent('');
        setFlagType('');

        toggleFlagModal();
    }

    const handleFlagSubmit = async (e) => {
        e.preventDefault();
        const data = {
          taxon_name: taxon.taxon_name,
          taxon_id: taxon.taxon_id,
          flag_content: flagContent,
          flag_type: flagType
        };
    
        await axios.post('/api/flags', data)
          .then(response => {
            setFlagContent('');
            setFlagType('');
    
            toggleFlagModal();
            notify('tr', 'Flag submitted successfully!', 'success');
          })
          .catch((error) => {
            notify('tr', error.message, 'danger')
          })
      };

    const toggleFlagModal = () => {
        setFlagModalOpen(!flagModalOpen);
    }

    useEffect(() => {
    }, [flagModalOpen])

    return (
      <Modal isOpen={flagModalOpen} toggle={toggleFlagModal} className="flag-modal-element">
        <Form onSubmit={handleFlagSubmit}>
            <CardHeader>
            <CardTitle tag="h3" className="impactful" style={{textAlign: 'center', marginBottom: 0, padding: '10px 10px 0px'}}>
                Flag Taxon: {taxon.taxon_name}
            </CardTitle>
            </CardHeader>
            <CardBody>
            <div className="flag-modal-content">
                <FormGroup className="flag-modal-form-group">
                <Label for="flagType" tag="b">Flag type</Label>
                <Input
                    type="select" 
                    name="flag_type" 
                    id="flagType" 
                    value={flagType} 
                    onChange={(e) => setFlagType(e.target.value)}
                    required
                >
                    <option value="">Select flag type</option> {/* Default placeholder */}
                    <option value="inappropriate">Inappropriate</option>
                    <option value="incorrect-description">Incorrect description</option>
                    <option value="copyright-violation">Copyright Violation</option>
                </Input>
                </FormGroup>
                <br />
                <FormGroup className="flag-modal-form-group">
                <Label for="flagContent" tag="b">Flag content</Label>
                <Input
                    type="textarea"
                    name="flag_content"
                    id="flagContent"
                    rows="6"
                    placeholder="Describe the issue here..."
                    value={flagContent}
                    onChange={(e) => setFlagContent(e.target.value)}
                    required
                />
                </FormGroup>
            </div>
            </CardBody>
            <CardFooter className="flag-modal-button-section">
            <Button color="danger" onClick={handleFlagCancel}>Cancel</Button>
            <Button type="submit">Submit Flag</Button>
            </CardFooter>
        </Form>
        </Modal>
    )
}