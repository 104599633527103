import React, { useState, useEffect, } from 'react';
import Helmet from 'react-helmet';
import { Button, Card, CardHeader, CardFooter, CardBody, CardTitle, Row, Col, Input, ListGroup, ListGroupItem } from 'reactstrap';
import CharacterGroup from '../components/TaxoKeysCards/CharacterGlossaryGroup.js';
import axios from 'axios';
import Loading from '../components/Helpers/Loading.js';

const CharacterGlossary = () => {
  const [selectedCharacterGroup, setSelectedCharacterGroup] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [allCharacterGroups, setAllCharacterGroups] = useState([]);
  const [filteredCharacterGroups, setFilteredCharacterGroups] = useState([]);
  const [displayedCharacterGroups, setDisplayedCharacterGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    /*const fetchCharacters = async () => {
      try {
        const response = await axios.get('/api/characters');
        setAllCharacters(response.data || []);
      } catch (error) {
        console.error("Failed to fetch characters:", error);
      } finally {
        setIsLoading(false);
      }
    };*/

    const fetchCharacterGroups = async () => {
      try {
        const response = await axios.get('/api/character-groups');
        setAllCharacterGroups(response.data || []);
      } catch (error) {
        console.error("Failed to fetch characters:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchCharacterGroups();
  }, []);

  useEffect(() => {
    if (allCharacterGroups.length > 0) {
      const filtered = searchTerm
        ? allCharacterGroups.filter(group => group.toLowerCase().includes(searchTerm.toLowerCase()))
        : allCharacterGroups

      setFilteredCharacterGroups(filtered)

      setDisplayedCharacterGroups(filtered.slice(page * itemsPerPage, (page + 1) * itemsPerPage));
    }
  }, [searchTerm, allCharacterGroups, page]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page with new filter
  };

  const handleCharacterGroupClick = (group) => {
    setSelectedCharacterGroup(group);
  };

  const handlePreviousPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 0));
  };

  const handleNextPage = () => {
    setPage(prevPage => Math.min(prevPage + 1, Math.ceil(allCharacterGroups.length / itemsPerPage) - 1));
  };

  const getPageCount = (totalItems, itemsPerPage) => {
    const pageCount = Math.ceil(totalItems / itemsPerPage);
    return pageCount > 99 ? "99+" : pageCount;
  };

  return (
    <div className="content" style={{ display: 'flex', flexWrap: 'wrap' }}>
      <Helmet>
        <title>Character Glossary | TaxoKeys</title>
      </Helmet>
      <Row style={{ width: '100%' }}>
        <Col md="4">
          <Card className="search-container">
            <CardHeader>
              <CardTitle tag="h3" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <i className="nc-icon nc-book-bookmark" />
                <div style={{marginLeft: '5px'}}>Browse Characters</div>
              </CardTitle>
            </CardHeader>
            <CardBody className="search-body">
              <Input
                type="search"
                name="group_name"
                placeholder="Search character groups..."
                onChange={handleSearchChange}
                value={searchTerm}
                style={{ marginBottom: '15px' }}
              />
              <div style={{ flex: 1, overflowY: "auto", position: "relative" }}>
                {isLoading ? (
                  <Loading className="loading" />
                ) : (
                  <ListGroup style={{flex: 1, overflowY: "auto"}}>
                    {displayedCharacterGroups.map((characterGroup, index) => (
                      <ListGroupItem
                        key={characterGroup}
                        tag="a"
                        href="#"
                        action
                        onClick={(e) => {
                          e.preventDefault();
                          handleCharacterGroupClick(characterGroup);
                        }}
                        className={selectedCharacterGroup === characterGroup ? 'active' : ''}
                        style={{ backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#e9ecef' }}
                      >
                        {characterGroup.group_name.charAt(0).toUpperCase() + characterGroup.group_name.slice(1).toLowerCase()}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                )}
              </div>
            </CardBody>
            <CardFooter style={{ height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Button className="btn" onClick={handlePreviousPage} disabled={page === 0} style={{ marginRight: '4px', width: "12em" }}>
                Previous Page
              </Button>
              <p style={{margin: "0px", minWidth: "30px"}}>{filteredCharacterGroups.length !== 0 ? page + 1 : 0} / {getPageCount(filteredCharacterGroups.length, itemsPerPage)}</p>
              <Button className="btn" onClick={handleNextPage} disabled={(page + 1) * itemsPerPage >= filteredCharacterGroups.length} style={{ width: "12em" }}>
                Next Page
              </Button>
            </CardFooter>
          </Card>
        </Col>
        <Col md="8">
          {selectedCharacterGroup && (
            <>
              <Card className="description-container" style={{ height: 'calc(100vh - 143px)' }}>
                <CardBody style={{ overflowY: 'auto' }}>
                  <CharacterGroup group={selectedCharacterGroup} />
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CharacterGlossary;
