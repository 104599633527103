// isAuth.js
import axios from 'axios';

export const checkAuthStatus = async () => {
    return await axios.get('/api/check-auth', {headers: {'Cache-Control': 'no-cache', 'Pragma': 'no-cache'}})
      .then(response => {
        return response.data.isAuthenticated
      })
      .catch(function (error) {
        console.error(error.message)
      });
};