// TagInput.js
import { faClock, faEarth } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

const TagInput = ({ locations, suggestedValues, selectedTags, onTagsChange }) => {
  const [input, setInput] = useState('');
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleDropdownChange = (tag, field, value) => {
    console.log(`Changing ${field} to: ${value} for tag: ${tag.value}`); // Debugging
    const updatedTags = selectedTags.map(t => 
      t.value === tag.value ? { ...t, [field]: value } : t
    );
    console.log("Updated tags:", updatedTags);  // Debugging

    onTagsChange(updatedTags);  // Ensure this triggers a parent state update
  };

  // Currently partially unimplemented -- see below
  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (input && !selectedTags.some(t => t.value === input)) {
        const newTag = { value: input, frequency: 'always', location: '' };
        onTagsChange([...selectedTags, newTag]);
      }
      setInput('');
    } else if (e.key === 'Backspace' && !input) {
      removeTag(selectedTags[selectedTags.length - 1]);
    }
  };

  // Currently unimplemented -- should eventually check if tag not in selectedValues, then maybe warn user?
  const handleInputBlur = () => {
    if (input && !selectedTags.some(t => t.value === input)) {
      const newTag = { value: input, frequency: 'always', location: '' }; // Default values
      onTagsChange([...selectedTags, newTag]);
    }
    setInput('');
  };

  const removeTag = (tag) => {
    onTagsChange(selectedTags.filter((t) => t !== tag));
  };

  return (
    <div className="tags-input-container">
      {selectedTags.map((tag, index) => (
        <div key={index} className="tag-item">
          {locations.length > 0 && (
            <UncontrolledDropdown className="tag-uncontrolled-dropdown">
              <DropdownToggle color="info" className="location-dropdown-toggle" caret>{tag.location !== '' ? tag.location : <FontAwesomeIcon icon={faEarth}/>}</DropdownToggle>
              <DropdownMenu className="location-dropdown-menu">
                <DropdownItem onClick={() => handleDropdownChange(tag, 'location', '')}>---</DropdownItem>
                {locations.map((location, index) => (
                  <DropdownItem key={index} onClick={() => handleDropdownChange(tag, 'location', location)}>
                    {location}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
            <UncontrolledDropdown className="tag-uncontrolled-dropdown">
              <DropdownToggle caret>{tag.frequency !== 'always' ? tag.frequency : <FontAwesomeIcon icon={faClock}/>}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleDropdownChange(tag, 'frequency', 'always')}>always</DropdownItem>
                <DropdownItem onClick={() => handleDropdownChange(tag, 'frequency', 'sometimes')}>sometimes</DropdownItem>
                <DropdownItem onClick={() => handleDropdownChange(tag, 'frequency', 'rarely')}>rarely</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          <div>{tag.value}</div>
          <button className="x-button" type="button" onClick={() => removeTag(tag)}>×</button>
        </div>
      ))}
      <input
        type="text"
        value={input}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onBlur={handleInputBlur}
        placeholder="Type and press enter"
        className="tag-input"
      />
    </div>
  );
};

export default TagInput;
