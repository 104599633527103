import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import ListTaxa from './ListTaxa.js';

const DropdownSearch = ({ onDocumentClick, collectionName, itemLabelKey, id, placeholder, defaultValue, direction, displayAll, highlight }) => {
  const [documentName, setDocumentName] = useState('');
  const [results, setResults] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fetchingResults, setFetchingResults] = useState(true);
  const searchTimeout = useRef(null);

  // Function to fetch documents
  const getDocuments = (name) => {
    axios
      .get(`/api/${collectionName}?${itemLabelKey}=${name}`)
      .then((res) => {
        if (res.data) {
          const dataObject = res.data.results;
          const sortedDocuments = dataObject.sort((a, b) => {
            const aLabel = a[itemLabelKey].toLowerCase();
            const bLabel = b[itemLabelKey].toLowerCase();
            const lowercasedName = name.toLowerCase();
          
            // Consider exact match
            if (aLabel === lowercasedName) {
              return -2;
            } else if (bLabel === lowercasedName) {
              return 2;
            }
          
            // Compare by the length of the matched prefix
            const aPrefixLength = aLabel.startsWith(lowercasedName) ? lowercasedName.length : 0;
            const bPrefixLength = bLabel.startsWith(lowercasedName) ? lowercasedName.length : 0;
          
            // Sort by prefix length in ascending order
            if (aPrefixLength > bPrefixLength) {
              return -1;
            } else if (aPrefixLength < bPrefixLength) {
              return 1;
            }
          
            // Default sorting based on label
            return aLabel.localeCompare(bLabel);
          });

          setResults(sortedDocuments);
          setFetchingResults(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // Effect to fetch documents based on defaultValue
  useEffect(() => {
    if (defaultValue) {
      setDocumentName(defaultValue);
      getDocuments(defaultValue);
    } else {
      getDocuments('');  // Fetch all documents or the default empty search
    }
  }, [defaultValue]);  // Only watch for changes in defaultValue

  const handleChange = (e) => {
    const value = e.target.value;
    setFetchingResults(!Boolean(results.length));
    setDocumentName(value);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      getDocuments(value);
    }, 250);
    if (value !== '' || displayAll) {
      setDropdownOpen(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();  // Prevent the default behavior of form submission
    }
  };

  return (
    <>
      <input
        type="text"
        className="form-control"
        id={id}
        name="name"
        placeholder={placeholder ?? "Search..."}
        onClick={() => setDropdownOpen(true)} // Open dropdown when the input is focused
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={documentName || ''}
        autoComplete="off"
        style={{border: highlight ? '2px solid red' : '1px solid #d3d3d3'}}
      />
      <div className="tooltip-dropdown">
        <Dropdown direction={direction} isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} style={{marginLeft: 20}}>
          <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen} />
          <DropdownMenu style={{marginBottom: direction === 'up' ? '50px' : 0}}>
            <ListTaxa 
              items={results}
              fetchingResults={fetchingResults}
              onItemSelect={(selectedItem) => {
                onDocumentClick(selectedItem);
                setDocumentName(selectedItem[itemLabelKey]);
                setDropdownOpen(false);
              }}
              itemLabelKey={itemLabelKey}
              onlyParents={collectionName === 'taxa'}
            />
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  );
};

export default DropdownSearch;
