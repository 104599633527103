import axios from "axios"
import { useEffect, useState } from "react"
import Loading from "../components/Helpers/Loading"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"

export default function FlagsPage() {
    const [flags, setFlags] = useState(null)

    useEffect(() => {
        axios.get('/api/flags')
            .then((response) => {
                setFlags(response.data)
            })
            .catch((error) => {
                console.log(error.message);
            })
    }, [])

    return (
        <div className="content limited-content">
            {
                flags == null ? (
                    <Loading />
                ) : (
                    flags.length > 0 ? (
                        flags.map((flag, index) => (
                            <Card key={index}>
                                <CardHeader>
                                    <div style={{display: 'flex', alignItems: 'flex-end'}}>
                                        <CardTitle tag="h3" className="impactful" style={{margin: 0}}>
                                            {flag.taxon_name}
                                        </CardTitle>
                                        <span style={{display: 'inline-block', color: '#999', fontSize: '1rem', marginLeft: '7px', marginBottom: '5px'}}>(id: {flag.taxon_id})</span>
                                    </div>
                                    <CardTitle tag="h5" className="impactful">{flag.flag_type}</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <p>{flag.flag_content}</p>
                                </CardBody>
                            </Card>
                        ))
                    ) : (
                        <h2 style={{textAlign: 'center'}}>No flags.</h2>
                    )
                )
            }
        </div>
    )
}