import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import { Card, CardBody, Row, Col, CardFooter } from 'reactstrap';
import DropdownSearch from '../components/Helpers/DropdownSearch';
import ProgressDisplay from '../components/Helpers/ProgressDisplay';
import KeyFlowchart from '../components/ReactFlow/KeyFlowchartEdgeLabel';
import { v4 as uuidv4 } from 'uuid'; // Import UUID generator
import { Form, useLocation } from 'react-router-dom';
import { TaxonProvider } from '../util/TaxonContext';

const KeysPage = () => {
  const [selectedTaxonId, setSelectedTaxonId] = useState(0);
  const [selectedTaxonName, setSelectedTaxonName] = useState('');
  const [currentKeyTaxonId, setCurrentKeyTaxonId] = useState(0);
  const [configName, setConfigName] = useState('default');
  const [progressReset, setProgressReset] = useState(false);
  const [blobUrl, setBlobUrl] = useState(null);
  const [keyJson, setKeyJson] = useState(null);
  const [responseError, setResponseError] = useState(false);
  const [sessionId, setSessionId] = useState('');

  const [highlightTaxon, setHighlightTaxon] = useState(false);

  const location = useLocation();

  const base64ToBlob = (base64, type = 'application/octet-stream') => {
    const binary = atob(base64);
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    return new Blob([view], { type });
  };

  useEffect(() => {
    setSessionId(uuidv4())
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initialTaxonId = params.get('initial_taxon_id');
    const initialTaxonName = params.get('initial_taxon_name');
  
    setSelectedTaxonId(initialTaxonId);
    setSelectedTaxonName(initialTaxonName || '');
  }, [location]);

  const generateKeyAndFlowchart = async () => {
    setHighlightTaxon(selectedTaxonName === '')
    if (selectedTaxonName !== '') {
      setResponseError(false);
      setBlobUrl(null);
      setCurrentKeyTaxonId(selectedTaxonId);
      setProgressReset(true);  // Trigger reset

      const keyGeneratorInput = {
        taxon_id: selectedTaxonId, 
        config_name: configName,
        session_id: sessionId 
      };

      try {
        const response = await axios.post('/api/run-key-generator', keyGeneratorInput);
        const { pdf, tree } = response.data;
        setKeyJson(tree);

        const pdfBlob = base64ToBlob(pdf, 'application/pdf');
        const url = URL.createObjectURL(pdfBlob);
        setBlobUrl(url);
      } catch (error) {
        console.error('Error:', error);
        setResponseError(true);
      } finally {
        setProgressReset(false);
      }
    }
  };

  const handleConfigChange = (e) => {
    const { value } = e.target;
    setConfigName(value);
  };

  const handleTaxonClick = (taxonData) => {
    setSelectedTaxonId(taxonData.taxon_id);
    setSelectedTaxonName(taxonData.taxon_name);
  };

  // remember className="key-gen"
  return (
    <>
      <div className="content">
        <Helmet>
          <title>Generate Dichotomous Key | TaxoKeys</title>
        </Helmet>
        <Row>
          <Col md="12">
            <Card className="key-gen">
              <CardBody className="key-flowchart-container" >
                {keyJson ? (
                  <KeyFlowchart keyJson={keyJson}/>
                ) : (
                  <div className="key-instructions-container">
                    <div className='key-instructions'>
                      <h3 style={{fontWeight: 'bold', textAlign: 'center', marginBottom: '15px'}}><b>
                        Get Started
                      </b></h3>
                      <ol>
                        <li style={{marginBottom: '10px'}}>Select a taxon you'd like help with identifying (try 'Physarales'!)</li>
                        <li style={{marginBottom: '10px'}}>Optionally, select a config file. This helps the algorithm classify species.</li>
                        <li>Hit 'Create Key'! TaxoKeys will take care of the rest.</li>
                      </ol>
                      {/*
                        <Card>
                            <video controls>
                                <source src="https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4"/>
                            </video>
                        </Card>
                      */}
                      <div className='text-danger' style={{textAlign: 'center'}}>
                        <small>Please consult with other resources and/or experts in the field before accepting results. </small>
                      </div>
                    </div>
                  </div>
                )}
              </CardBody>
              <CardFooter style={{ padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '0 0 12px 12px', boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }}>
                <Form className="simple-form" style={{ width: '100%' }}>
                  <div className="form-layout">
                    <div className="form-group">
                      <label htmlFor="taxon_name">Taxon:</label>
                      <DropdownSearch
                        onDocumentClick={handleTaxonClick}
                        collectionName={"taxa"}
                        itemLabelKey={"taxon_name"}
                        id="taxon_name"
                        direction="up"
                        defaultValue={selectedTaxonName}
                        placeholder="Search for a taxon..."
                        style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #ced4da', height: '40px' }}
                        displayAll
                        highlight={highlightTaxon}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="config_name">Config File:</label>
                      <select 
                        onChange={handleConfigChange}
                        name="config_name"
                        value={configName}
                        id="config_name"
                        style={{ width: '100%', padding: '8px', borderRadius: '5px', border: '1px solid #d3d3d3', height: '40px' }}
                      >
                        <option value="default">No config</option>
                        <option value="myxomycetes">Myxomycetes</option>
                      </select>
                    </div>

                    {/* Button Section */}
                    <div className="button-group">
                      <button className="btn btn-warning" type="button" style={{ height: '40px' }} onClick={generateKeyAndFlowchart}>
                        Create Key!
                      </button>
                      <button
                        className="btn btn-success"
                        type="button"
                        style={{ height: '40px' }}
                        onClick={() => {
                          if (blobUrl) {
                            window.open(blobUrl, '_blank');
                          }
                        }}
                        disabled={!blobUrl}
                      >
                        Download PDF
                      </button>
                    </div>
                  </div>
                </Form>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <ProgressDisplay 
                    taxonId={currentKeyTaxonId} 
                    sessionId={sessionId}
                    responseError={responseError}
                    progressReset={progressReset}
                    style={{ width: '80%', margin: '10px auto 0', height: '20px', backgroundColor: '#e9ecef', borderRadius: '10px' }}
                  />
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default KeysPage;