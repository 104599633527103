import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import Loading from "../Helpers/Loading";
import TaxonDescription from "./TaxonDescription";
import { useTaxon } from "../../util/TaxonContext";

const KeyModal = ({taxon_id, isOpen, toggle}) => {
    const { taxon, setTaxon } = useTaxon()
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (taxon_id && typeof taxon_id === 'string') {
            setIsLoading(true);  // Set loading state before the request
            axios.get(`/api/taxa?taxon_ids=${taxon_id}`)
                .then((res) => {
                    if (res.data.results.length !== 0) {
                        setTaxon(res.data.results[0]);  // Set taxon in context
                    } else {
                        throw new Error(`No taxon found for id ${taxon_id}!`);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching taxon data:", error);
                    setError(true);
                })
                .finally(() => setIsLoading(false));  // Reset loading state
        }
    }, [taxon_id]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="key-modal-element">
            {error ? (
                <div>Error fetching data.</div>
            ) : isLoading ? (
                <Loading />  // Show loading spinner while data is being fetched
            ) : (
                taxon && Object.keys(taxon).length > 0 ? (
                    <div className="key-modal-description">
                        <TaxonDescription isEditMode={false} setIsEditMode={() => {}} keyView={true} />
                    </div>
                ) : (
                    <div>No data found.</div>
                )
            )}
        </Modal>
    )
}

export default KeyModal;