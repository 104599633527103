import { DropdownItem } from 'reactstrap';

const ListTaxa = ({ items, fetchingResults, onItemSelect, itemLabelKey, onlyParents }) => {
  const taxaWithChildren = items.filter((taxon) => (taxon.subtaxon_ids && taxon.subtaxon_ids.length > 0))
  const slicedItems = (onlyParents ? taxaWithChildren : items).slice(0, 10) ; // Get first 10 items

  return (
    <>
      {fetchingResults ? (
        <DropdownItem>Fetching results...</DropdownItem>
      ) : (
        slicedItems && slicedItems.length > 0 ? (
          slicedItems.map((item) => (
            <DropdownItem
              key={item._id}
              onClick={() => onItemSelect(item)}
            >
              {item[itemLabelKey]}
            </DropdownItem>
          ))
        ) : (
          <DropdownItem>No items found</DropdownItem>
        )
      )}
    </>
  );
};

export default ListTaxa;
