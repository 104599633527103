/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";
import { useContext, useEffect, useRef, useState } from "react";
import Logout from "../../pages/LogoutPage";
import Login from "../../pages/LoginPage";
import { AuthContext } from "../../util/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-regular-svg-icons";

function Header(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [flattenedRoutes, setFlattenedRoutes] = useState([]);
  const [color, setColor] = useState("transparent");
  const [searchTerm, setSearchTerm] = useState("");
  const sidebarToggle = useRef();
  const location = useLocation();
  const { isAuthenticated } = useContext(AuthContext);

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };

  const flattenRoutes = (routes) => {
    return routes.reduce((acc, route) => {
      if (route.children) {
        return acc.concat(route.children);  // Flatten the children array
      }
      return acc.concat(route); // Include routes that don't have children
    }, []);
  };

  useEffect(() => {
    setFlattenedRoutes(flattenRoutes(props.routes));
  }, [])

  const getBrand = () => {
    let brandName = "TaxoKeys";
    flattenedRoutes.map((route, key) => {
      if (window.location.href.indexOf(route.path) !== -1) {
        brandName = route.name;
      }

      return null;
    });
    return brandName;
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };

  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      // Navigate to the new URL with the user's input as a query parameter
      window.location.href = `/search?name=${encodeURIComponent(searchTerm)}`;
    }
  };
  
  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        location.pathname.indexOf("full-screen-maps") !== -1 ? "dark" : color
      }
      expand="lg"
      className={
        location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler"
              onClick={() => openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="/">{getBrand()}</NavbarBrand>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <form onSubmit={handleSubmit}>
            <InputGroup className="no-border">
              <Input 
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="nc-icon nc-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </form>
          <Nav navbar className="tooltip-dropdown">
            {isAuthenticated && (
              <NavItem>
                <Link to="/flags" className="nav-link btn-magnify">
                  <FontAwesomeIcon icon={faFlag} className="nav-flag-icon" />
                  <p className="nav-flag-text">
                    <span className="d-lg-none d-md-block">Flag</span>
                  </p>
                </Link>
              </NavItem>
            )}
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={(e) => dropdownToggle(e)}
            >
              <DropdownToggle caret nav>
                <i className="nc-icon nc-single-02" />
                <p>
                  <span className="d-lg-none d-md-block">Account</span>
                </p>
              </DropdownToggle>
              <DropdownMenu right>
                {isAuthenticated ? (
                  <>
                    <DropdownItem tag="a" href="/profile">View profile</DropdownItem>
                    <DropdownItem tag="a" href="/logout">Log out</DropdownItem>
                  </>
                ) : (
                  <DropdownItem tag="a" href="/login">Log in</DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
