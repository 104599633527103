import { Button, CardImg } from "reactstrap";
import SimpleMyxo from "../../assets/img/myxo-placeholder-bic.png";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../util/AuthContext";

const TaxonPhotoController = ({taxon, setTaxon, isEditMode, iNaturalistId}) => {
    const [iNaturalistImageResults, setiNaturalistImageResults] = useState([]);
    const [iNaturalistImageIndex, setiNaturalistImageIndex] = useState(0);

    const { isAuthenticated } = useContext(AuthContext);

    const mod = (n, m) => {
      return ((n % m) + m) % m;
    }

    const populateiNatPhotos = () => {
        if (!isAuthenticated || !iNaturalistId) return;

        axios.get(`https://api.inaturalist.org/v1/observations?photos=true&taxon_id=${iNaturalistId}&license=cc-by-nc&quality_grade=research&order=desc&order_by=votes`)
            .then(response => {
            if (response.data.results.length > 0) {
                setiNaturalistImageResults(response.data.results)
                setiNaturalistImageIndex(0);
            }
            })
            .catch(error => {
            console.error("Error fetching iNaturalist data: ", error);
            });
    };

    useEffect(() => {
      if (iNaturalistImageResults.length) {
        setImageFromiNat(iNaturalistImageIndex);
      }
    }, [iNaturalistImageResults, iNaturalistImageIndex]);
  
    const handleClickRight = () => {
      const next_index = mod((iNaturalistImageIndex + 1), (iNaturalistImageResults.length - 1))
      setiNaturalistImageIndex(next_index);
    }
  
    const handleClickLeft = () => {
      const next_index = mod((iNaturalistImageIndex - 1), (iNaturalistImageResults.length - 1))
      setiNaturalistImageIndex(next_index);
    }
  
    const setImageFromiNat = (index) => {
      const observation_url = iNaturalistImageResults[index].uri;
      const first_photo = iNaturalistImageResults[index].observation_photos[0].photo;
      const owner_regex = /\(c\) ([^,]+)/;
      const match = first_photo.attribution.match(owner_regex);
      const photo_owner = match ? match[1] : "Photographer unknown";
      const photo_img = first_photo.url.replace('square', 'medium');
    
      // Pass the updated taxon state to saveChanges
      setTaxon((prevTaxon) => {
        const updatedTaxon = {
          ...prevTaxon,
          photo_img,
          photo_license: "cc-by-nc",
          photo_owner,
          photo_source: observation_url
        };
  
        return updatedTaxon;
      });
    }
  
    const removePhoto = () => {
      setTaxon((prevTaxon) => {
        const updatedTaxon = {
          ...prevTaxon,
          photo_img: '',
          photo_license: '',
          photo_owner: '',
          photo_source: ''
        };
  
        return updatedTaxon;
      });
      setiNaturalistImageIndex(0);
      setiNaturalistImageResults([]);
    }

    return (
        <div className="taxon-description-img-container">
            {taxon.photo_img ? (
                <>
                <div className="taxon-result-attribution-container">
                    <div className="taxon-result-attribution-i">i</div>
                    <div className="taxon-result-attribution-content">
                    <p className="taxon-result-attribution-entry">© {taxon.photo_owner}</p><p className="taxon-result-attribution-entry">{taxon.photo_license}</p><a className="taxon-result-attribution-entry" href={taxon.photo_source}>source</a>
                    </div>
                </div>
                <CardImg className="taxon-description-img" src={taxon.photo_img} alt={taxon.taxon_name} style={{ borderRadius: '12px' }} />
                {isAuthenticated && isEditMode && (
                    <div className="image-control-grid">
                    {!iNaturalistImageResults.length ? (
                        <>
                        <div className='filler' />
                        <Button color="danger" disabled={!iNaturalistId} onClick={removePhoto} className="image-control-btn image-remove-btn">
                            <i className="nc-icon nc-simple-remove"></i>
                        </Button>
                        </>
                    ) : (
                        <>
                        <Button color="danger" onClick={removePhoto} className="image-control-btn image-remove-btn">
                            <i className="nc-icon nc-simple-remove"></i>
                        </Button>
                        <Button color="success" onClick={() => {setiNaturalistImageResults([]); setiNaturalistImageIndex(0)}} className="image-control-btn image-accept-btn">
                            <i className="nc-icon nc-check-2"></i>
                        </Button>
                        <Button color="info" onClick={() => {handleClickRight([])}} className="image-control-btn image-left-btn">
                            <i className="nc-icon nc-minimal-left"></i>
                        </Button>
                        <Button color="primary" onClick={() => {handleClickLeft([])}} className="image-control-btn image-right-btn">
                            <i className="nc-icon nc-minimal-right"></i>
                        </Button>
                        </>
                    )}
                    </div>
                )}
                </>
            ) : (
                <>
                <CardImg className="taxon-result-img" src={SimpleMyxo} alt="Placeholder that would otherwise display this card's taxon." style={{filter: `invert(0.9)   hue-rotate(220deg)`, border: '1px solid #c3c3c3', borderRadius: '12px', backgroundColor: '#eee', padding: '10%' }} />
                {isAuthenticated && isEditMode && (
                    <div className="image-control-grid">
                        <div className='filler' />
                        <Button disabled={!iNaturalistId} onClick={populateiNatPhotos} className="image-control-btn">
                            <i className="nc-icon nc-zoom-split"></i>
                        </Button>
                    </div>
                )}
                </>
            )}
        </div>
    )
};

export default TaxonPhotoController