import { Button, Card, CardBody, CardHeader, CardTitle, Col, FormGroup, Input, Row, Table } from 'reactstrap';
import TagInput from './TagInput';
import ColorBubble from './ColorBubble';
import { useTaxon } from '../../util/TaxonContext';

const ShowTaxonCharacters = ({ groups, isEditMode, isLoading, onCharacterChange, onCharacterDelete }) => {
  const { taxon } = useTaxon(); // Use the taxon object from context

  const handleChange = (e, character) => {
    onCharacterChange(e.target.value, character);
  };

  const handleDelete = (characterName) => {
    onCharacterDelete(characterName);
  };

  const renderCharacter = (characterName, groupName, isEditable) => {
    const character = taxon.characters[characterName];
    const renderColors = characterName.includes('color')
    let groupLocations;

    const last_index = character.tags.length - 1;

    if (groups && Object.keys(groups).includes(groupName)) {
      groupLocations = groups[groupName.toLowerCase()].sublocations
    } else {
      groupLocations = [];
    }

    return (
      <>
        <th className="th-char">
          {isEditMode && (
            <Button color="danger" onClick={() => handleDelete(characterName)} className="character-remove-btn">
              <i className="nc-icon nc-simple-remove"></i>
            </Button>
          )}
          {characterName}
        </th>
        <td className="td-char">
          {isEditMode ? (
            <TagInput
              suggestedValues={character.suggested_values || []} // Assuming all characters can have suggested values or an empty array if not
              selectedTags={character.tags}
              onTagsChange={(newTags) => handleChange({ target: { value: newTags } }, characterName)}
              locations={groupLocations}
            />
          ) : (
            character.tags.map((tag, index) => (
              <div key={tag.value} className="character-value-inline" style={{ marginRight: renderColors ? '2px' : '' }}>
                {renderColors && (
                  <ColorBubble color={tag.value.toLowerCase()} index={index} />
                )}
                <span>
                  {tag.location.toLowerCase() !== '' && (
                    tag.location.toLowerCase() + ' '
                  )}
                  {tag.frequency.toLowerCase() !== 'always' && (
                    tag.frequency.toLowerCase() + ' '
                  )}
                  {tag.value.toLowerCase()}
                  {index < last_index ? ',\u00A0' : ''}
                </span>
              </div>
            ))
          )}
        </td>
      </>
    );
  };

  const renderGroup = (groupName, isEditable, index) => {
    const groupCharacters = groupedCharacters[groupName];

    return (
      <Col
        key={groupName}
        {...(isEditMode
          ? { xs: "12", lg: "12", xl: "6" }  // Breakpoints for edit mode
          : { xs: "12", lg: "6", xl: "4" })}  // Breakpoints for view mode
        className="taxon-character-col"
      >
        <Card className="taxon-character-group" style={{marginBottom: '10px'}}>
          <CardHeader>
            <CardTitle tag="h5" className="taxon-character-header">
              {groupName}
              {groups && Object.keys(groups).includes(groupName) && (
                <div className="definition-bubble" title={groups[groupName.toLowerCase()].description}>
                  <div className="definition-question-mark">?</div>
                </div>
              )}
            </CardTitle>
            <hr style={{margin: 0}}/>
          </CardHeader>
          <CardBody>
            <Table borderless>
              <tbody>
                {groupCharacters.map((characterName, i) => (
                  <tr key={characterName}>
                    {renderCharacter(characterName, groupName, isEditable)}
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    );
  };

  // Group characters by their "group" entries
  const groupedCharacters = {};
  taxon.characters && (
    Object.keys(taxon.characters).forEach((characterName) => {
        const character = taxon.characters[characterName];
        const groupName = character.group || 'Other'; // Default group if not specified -- shouldn't ever happen, but this is a last resort
        groupedCharacters[groupName] = groupedCharacters[groupName] || [];
        groupedCharacters[groupName].push(characterName);
    })
  )

  // Check if there are characters
  const hasCharacters = Object.keys(groupedCharacters).length > 0;

  return (
    <>
      <Row>
        {hasCharacters ? (
          <>
            {Object.keys(groupedCharacters).sort().map((groupName, index) =>
              renderGroup(groupName, isEditMode, index)
            )}
          </>
        ) : (
          !isEditMode && (
            <Col>
              <Card className="taxon-character-group" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px'}}>
                <h5 style={{marginBottom: 0}}>No data available for taxon.</h5>
              </Card>
            </Col>
          )
        )}
        {isEditMode && (
          <Col
            xs="12" lg="12" xl="6"  // Breakpoints for edit mode
            className="taxon-character-col"
          >
            <Card style={{ height: '100%', minHeight: '200px' }}>
              <CardBody style={{ display: 'flex', flexDirection: 'column', gap: '5px', alignItems: 'center', justifyContent: 'center', }}>
                <CardTitle tag="h5" className="taxon-character-header">
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px'}}>
                    <i className="nc-icon nc-simple-add" style={{backgroundColor: '#44c47d', padding: '5px', borderRadius: '99px', color: 'white', fontSize: 12, fontWeight: 'bold'}}/>
                    <div>Add Character Group</div>
                  </div>
                </CardTitle>
                <FormGroup>
                  <Input></Input>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      
    </>
  );
};

export default ShowTaxonCharacters;