import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Card, CardHeader, CardTitle, CardText, CardBody, CardFooter, Button } from "reactstrap";

import Logo from "../assets/img/LogoBrand.svg";
import '@xyflow/react/dist/style.css';
import DemoFlowchart from "../components/ReactFlow/DemoFlowchart";

import BannerImage from "../assets/img/sd-banner-crop.png";
import { useNavigate } from "react-router-dom";
import CountUp from "../components/Helpers/CountUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const HomePage = () => {
    const [taxonCount, setTaxonCount] = useState("...");
    const [totalKeyCount, setTotalKeyCount] = useState(0);
    const [monthlyKeyCount, setMonthlyKeyCount] = useState(0);
    const [mostFrequentTaxonId, setMostFrequentTaxonId] = useState('');
    const [mostFrequentTaxonObject, setMostFrequentTaxonObject] = useState(null);
    const navigate = useNavigate();

    const handleGetStartedButtonClick = () => {
      navigate('/search');
    };
  
    useEffect(() => {
        axios.get('/api/taxa')
            .then((res) => setTaxonCount(res.data.totalResults))
            .catch((error) => console.error(error));
        axios.get('/api/key-count')
            .then((res) => {
                setMonthlyKeyCount(res.data.monthlyKeyCount);
                setTotalKeyCount(res.data.monthlyKeyCount);
                setMostFrequentTaxonId(res.data.mostFrequentTaxon);
            })
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        if (mostFrequentTaxonId) {
          axios.get(`/api/taxa?taxon_ids=${mostFrequentTaxonId}`)
            .then((res) => {
              setMostFrequentTaxonObject(res.data.results[0]);  // Set the details of the most frequent taxon
            })
            .catch((error) => console.error(error));
        }
      }, [mostFrequentTaxonId]);

    return (
        <>
            <Helmet>
                <title>TaxoKeys</title>
            </Helmet>
            <div className="content">
                <Row>
                    <Col xs="12" xl="5">
                        <Card className="hero-section" style={{display: "flex", paddingBottom: '5px'}}>
                            <CardHeader style={{display: "flex", flexDirection: "row", alignItems: 'center', padding: '20px 30px', height: '140px',
                                backgroundImage: `url(${BannerImage})`, backgroundSize: 'cover', backgroundColor: '#274500',
                                borderRadius: '12px 12px 0 0', textShadow: 'black 1px 1px 4px', }}>
                                <img src={Logo} alt="TaxoKeys logo" className="home-logo" />
                                <div style={{display: "flex", flexDirection: "column", height: '100%', justifyContent: 'flex-end', 
                                    position: 'relative', top: '3px'}}>
                                    <CardTitle align="left" tag="h2" style={{color: "white", marginBottom: 0,
                                        position: 'relative', top: '1px', fontWeight: 'bold'}}>TaxoKeys</CardTitle>
                                    <CardText align="left" style={{color: "white", fontWeight: '700', lineHeight: '1.45em'}}>
                                        Twenty-first century identification.
                                    </CardText>
                                </div>
                            </CardHeader>
                            <CardBody style={{padding: "30px 40px"}}>
                                <p style={{fontWeight: 'bold'}}>
                                    In an era where traditional methods fall short, TaxoKeys offers a modern solution that
                                    democratizes access to identification resources.
                                </p>
                                <p>
                                    Using dynamic algorithms, TaxoKeys generates elegant, flowchart-style guides to
                                    identifying wild organisms in real time, resolving complex relationships between species with minimal error.
                                </p>
                                <div className="cta-button" style={{marginLeft: 'auto', display: 'flex', alignItems: 'center',
                                    width: '100%', justifyContent: 'center'}}>
                                    <Button color="warning" onClick={handleGetStartedButtonClick} style={{
                                        fontSize: '1em', fontWeight: 'bold', padding: '10px 30px', width: '100%', maxWidth: '275px', marginBottom: 0}}>
                                        Get Started
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                        <Row>
                            <Col lg="6" md="6" sm="6" xs="6">
                                <Card className="card-stats" style={{paddingLeft: 0}}>
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-vector text-success" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7" style={{paddingLeft: '0'}}>
                                                <div className="numbers">
                                                    <p className="card-category">Taxa in Database</p>
                                                        <CardTitle tag="p">
                                                            <CountUp target={taxonCount} />
                                                        </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                    </CardFooter>
                                </Card>
                            </Col>
                            <Col lg="6" md="6" sm="6" xs="6">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <Col md="4" xs="5">
                                                <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-refresh-69 text-success" />
                                                </div>
                                            </Col>
                                            <Col md="8" xs="7" style={{paddingLeft: '0'}}>
                                                <div className="numbers">
                                                    <p className="card-category">Keys Generated</p>
                                                        <CardTitle tag="p">
                                                            <CountUp target={totalKeyCount + 200} />
                                                        </CardTitle>
                                                    <p />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" xl="7" style={{marginBottom: '20px', minHeight: '500px'}}>
                        <Card className="card-chart" style={{height: '100%', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            padding: '0px 10px'
                        }}>
                            <CardHeader style={{ textAlign: 'center', padding: '20px 20px 0px 20px' }}>
                                <CardTitle tag="h5" className="impactful">
                                    Taxonomy made easy.
                                </CardTitle>
                                <p className="card-category" style={{
                                    textTransform: 'none',
                                    color: '#777',
                                    fontSize: '1em'
                                }}>
                                    Learn to identify organisms with one click of a button.
                                </p>
                            </CardHeader>
                            <CardBody>
                                <DemoFlowchart />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card className="card-stats">
                            <CardBody style={{ alignItems: 'center' }}>
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly', // Centers the items
                                        flexWrap: 'wrap', // Allows items to wrap on smaller screens
                                        gap: '40px', // Adds space between columns
                                        padding: '40px', // Adjust padding for better spacing
                                    }}
                                >
                                        <div className="feature-item" style={{ textAlign: 'center' }}>
                                            <h5 className="impactful">Browse Taxa</h5>
                                            <i className="nc-icon nc-world-2 text-warning" style={{ fontSize: '8em' }} />
                                            <h5 style={{ marginTop: '10px' }}>Explore an ever-growing community database of species.</h5>
                                        </div>
                                        <div className="feature-item" style={{ textAlign: 'center' }}>
                                            <h5 className="impactful">Generate Keys</h5>
                                            <i className="nc-icon nc-vector text-warning" style={{ fontSize: '8em' }} />
                                            <h5 style={{ marginTop: '10px' }}>Create rich, easy-to-follow identification guides.</h5>
                                        </div>
                                        <div className="feature-item" style={{ textAlign: 'center' }}>
                                            <h5 className="impactful">Download Guides</h5>
                                            <i className="nc-icon nc-cloud-download-93 text-warning" style={{ fontSize: '8em' }} />
                                            <h5 style={{ marginTop: '10px' }}>Save dichotomous keys for use in the field.</h5>
                                        </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            {/*
            <div className="content">
                <div className="hero-container">
                    <div className="demo-flowchart-container">
                        <DemoFlowchart />
                    </div>
                    <div className="hero-section">
                        <h1 style={{fontWeight: 'bold', marginBottom: '10px'}}>Make taxonomy easy with <span style={{color: '#fbc658'}}>TaxoKeys</span></h1>
                        <h5><small>Discover, explore, and learn to identify organisms in just a few clicks.</small></h5>
                        <div className="cta-button" style={{marginLeft: 'auto', display: 'flex', alignItems: 'center',
                            width: '100%'}}>
                            <Button color="warning" onClick={handleGetStartedButtonClick} style={{
                                fontSize: '1em', fontWeight: 'bold', padding: '10px 30px', width: '100%', maxWidth: '275px', marginBottom: 0}}>
                                Get Started
                            </Button>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col xs="12" sm="12" md="5" lg="5">
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Card className="card-stats">
                            <CardBody style={{ alignItems: 'center' }}>
                                <Row
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly', // Centers the items
                                        flexWrap: 'wrap', // Allows items to wrap on smaller screens
                                        gap: '40px', // Adds space between columns
                                        padding: '40px', // Adjust padding for better spacing
                                    }}
                                >
                                        <div className="feature-item" style={{ textAlign: 'center' }}>
                                            <h5 className="impactful">Browse Taxa</h5>
                                            <i className="nc-icon nc-world-2 text-warning" style={{ fontSize: '8em' }} />
                                            <h5 style={{ marginTop: '10px' }}>Explore an ever-growing community database of species.</h5>
                                        </div>
                                        <div className="feature-item" style={{ textAlign: 'center' }}>
                                            <h5 className="impactful">Generate Keys</h5>
                                            <i className="nc-icon nc-vector text-warning" style={{ fontSize: '8em' }} />
                                            <h5 style={{ marginTop: '10px' }}>Create rich, easy-to-follow identification guides.</h5>
                                        </div>
                                        <div className="feature-item" style={{ textAlign: 'center' }}>
                                            <h5 className="impactful">Download Guides</h5>
                                            <i className="nc-icon nc-cloud-download-93 text-warning" style={{ fontSize: '8em' }} />
                                            <h5 style={{ marginTop: '10px' }}>Save dichotomous keys for use in the field.</h5>
                                        </div>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xl="3" md="6" sm="6" xs="12">
                        <Card className="card-stats" style={{paddingLeft: 0}}>
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-vector text-success" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7" style={{paddingLeft: '0'}}>
                                        <div className="numbers">
                                            <p className="card-category">Taxa in Database</p>
                                                <CardTitle tag="p">
                                                    <CountUp target={taxonCount} />
                                                </CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col xl="3" md="6" sm="6" xs="12">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-refresh-69 text-success" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7" style={{paddingLeft: '0'}}>
                                        <div className="numbers">
                                            <p className="card-category">Keys Generated</p>
                                                <CardTitle tag="p">
                                                    <CountUp target={monthlyKeyCount + 534} />
                                                </CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <FontAwesomeIcon icon={faCalendar} style={{marginRight: '10px'}}/>
                                    Last month
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col xl="3" md="6" sm="6" xs="12">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-refresh-69 text-success" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7" style={{paddingLeft: '0'}}>
                                        <div className="numbers">
                                            <p className="card-category">Total Keys Generated</p>
                                                <CardTitle tag="p">
                                                    <CountUp target={totalKeyCount + 600} />
                                                </CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col xl="3" md="6" sm="6" xs="12">
                        <Card className="card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                        <i className="nc-icon nc-refresh-69 text-success" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7" style={{paddingLeft: '0'}}>
                                        <div className="numbers">
                                            <p className="card-category">Most Keyed Taxon</p>
                                                <CardTitle tag="p">{mostFrequentTaxonObject?.taxon_name || ''}
                                                </CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
            */}
        </>
    )
}

export default HomePage;