import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import logo from "../../assets/img/LogoBrand.svg";
import { AuthContext } from "../../util/AuthContext";

function Sidebar(props) {
  const [adjustedRoutes, setAdjustedRoutes] = useState([]);
  const location = useLocation();
  const sidebarRef = useRef(null);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });

      return () => ps.destroy();
    }
  }, [location.pathname]);

  const activeRoute = (routeName) => {
    return (location.pathname === ("/" + routeName) || (location.pathname === '/taxon-description' && routeName === 'search')) ? "active" : "";
  };

  const flattenRoutes = (routes) => {
    return routes.reduce((acc, route) => {
      if (route.children) {
        return acc.concat(route.children);  // Flatten the children array
      }
      return acc.concat(route); // Include routes that don't have children
    }, []);
  };

  useLayoutEffect(() => {
    // Flatten the routes array and filter out login/logout routes
    const flattenedRoutes = flattenRoutes(props.routes).filter(route =>
      route.renderSidebar && route.path !== "/login" && route.path !== "/logout" && route.path !== "/taxon-description"
    );
    setAdjustedRoutes(flattenedRoutes);
  }, [props.routes]);

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="logo">
        <a href="/" className="simple-text logo-mini" style={{paddingBottom: 0}}>
          <img className="logo-img" src={logo} alt="Taxokeys Logo" />
        </a>
        <a href="/" className="simple-text logo-normal" style={{paddingLeft: '7px'}}>
          TaxoKeys
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebarRef}>
        <Nav className="top-nav">
          {adjustedRoutes.map((prop, index) => {
            if (prop.authRequired && !isAuthenticated) {
              return null; // Skip rendering this route if auth is required but user isn't authenticated
            }
            return (
              <li className={activeRoute(prop.path) + (prop.pro ? " active-pro" : "")} key={index}>
                <NavLink to={prop.path} className="nav-link">
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </NavLink>
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
