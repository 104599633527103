import chroma from "chroma-js";
import customColorMap from "../../util/colorMapping";

const validModifiers = [
    'dark',
    'light',
    'bright',
    'dull',
]

const getColorFromDescription = (description) => {
    const words = description.toLowerCase().split(/-| /);
    let iridescent = false;

    const colors = [];
    let modifier = null;

    for (let word of words) {
        const ishlessColor = word.replace('ish', '');

        if (customColorMap[ishlessColor]) {
            colors.push(customColorMap[ishlessColor]);
        } else if (chroma.valid(ishlessColor)) {
            colors.push(ishlessColor);
        } else if (validModifiers.includes(word)) {
            modifier = word;
        } else if (ishlessColor === "iridescent") {
            iridescent = true;
        }
    }

    // Get the color using tinycolor or chroma
    if (colors.length > 0) {
        let color = chroma.average(colors);
        // Apply modifiers
        if (modifier) {
            if (modifier === 'dark') {
                color = color.darken(1);
            } else if (modifier === 'light') {
                color = color.brighten(1);
            } else if (modifier === 'bright') {
                color = color.brighten(0.5).saturate(1);
            } else if (modifier === 'dull') {
                color = color.desaturate(1);
            } else {
                console.log(`Unknown modifier: ${modifier}`)
            }
        }

        return [color.hex(), iridescent];
    } else if (modifier) {
        switch(modifier) {
            case 'dark':
                return [chroma.hex('#33312f'), iridescent]
            case 'light':
                return [chroma.hex('#FFFAF0'), iridescent]
            default:
                return [null, iridescent]
        }
    }

    // Return default if no color matched
    return [null, iridescent];
}

const ColorBubble = ({color, index}) => {
    const [parsedColor, iridescent] = getColorFromDescription(color);

    return (
        <>
            {iridescent ? (
                <span className="color-bubble" style={{
                    backgroundImage: `
                    ${parsedColor ? `linear-gradient(${chroma(parsedColor).alpha(0.5).css()}, ${chroma(parsedColor).alpha(0.5).css()}),` : ''}
                    linear-gradient(
                        135deg,
                        rgba(255, 0, 0, 1) 15%,
                        rgba(255, 154, 0, 1) 25%,
                        rgba(208, 222, 33, 1) 35%,
                        rgba(79, 220, 74, 1) 45%,
                        rgba(63, 218, 216, 1) 55%,
                        rgba(28, 127, 238, 1) 65%,
                        rgba(95, 21, 242, 1) 75%,
                        rgba(186, 12, 248, 1) 85%,
                        rgba(251, 7, 217, 1) 95%,
                        rgba(255, 0, 0, 1) 100%
                    )
                `,
                    width: 12, border: '1px solid gray', marginRight: '3px',
                }} />
            ) : parsedColor ? (
                <span className="color-bubble" style={{ backgroundColor: parsedColor, width: 12, border: '1px solid gray', marginRight: '3px', }} />
            ) : (
                <span className="color-bubble" style={{ width: 0 }} />
            )}
        </>
    )
}

export default ColorBubble;