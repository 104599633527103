import { Card, CardBody, CardHeader, CardImg, CardTitle, Col, Modal, Row } from "reactstrap";
import SimpleMyxo from "../../assets/img/myxo-placeholder-bic.png";
import { useTaxon } from "../../util/TaxonContext";

const LookalikeModal = ({ lookalike, isOpen, toggle }) => {
    const { taxon } = useTaxon(); // Use the taxon object from context

    const toLowerCaseTagFields = (tag) => {
        return Object.fromEntries(
            Object.entries(tag).map(([key, value]) => [
                key, 
                typeof value === 'string' ? value.toLowerCase() : value // Convert to lowercase if the value is a string
            ])
        );
    };

    // Combine characters and highlight discrepancies
    const combineAndHighlightByGroup = (mainCharacters, lookalikeCharacters) => {
        const grouped = {};
        const allGroups = new Set([
            ...Object.values(mainCharacters).map(char => char.group),
            ...Object.values(lookalikeCharacters).map(char => char.group)
        ]);

        allGroups.forEach((group) => {
            const mainTagsInGroup = [];
            const lookalikeTagsInGroup = [];

            // Gather tags for the main taxon in this group
            Object.entries(mainCharacters).forEach(([characterName, characterObj]) => {
                if (characterObj.group === group) {
                    characterObj.tags.forEach(tag => {
                        mainTagsInGroup.push({
                            ...toLowerCaseTagFields(tag), // Spread all other properties of tag (location, frequency, etc.)
                            highlight: !lookalikeCharacters[characterName] || !lookalikeCharacters[characterName].tags.some(t => t.value.toLowerCase() === tag.value.toLowerCase())
                        });
                    });
                }
            });

            // Gather tags for the lookalike taxon in this group
            Object.entries(lookalikeCharacters).forEach(([characterName, characterObj]) => {
                if (characterObj.group === group) {
                    characterObj.tags.forEach(tag => {
                        lookalikeTagsInGroup.push({
                            ...toLowerCaseTagFields(tag), // Spread all other properties of tag (location, frequency, etc.)
                            highlight: !mainCharacters[characterName] || !mainCharacters[characterName].tags.some(t => t.value.toLowerCase() === tag.value.toLowerCase())
                        });
                    });
                }
            });

            // Only include the group if there's at least one difference
            const hasDifference = mainTagsInGroup.some(tag => tag.highlight) || lookalikeTagsInGroup.some(tag => tag.highlight);
            if (hasDifference) {
                grouped[group] = {
                    mainTags: mainTagsInGroup,
                    lookalikeTags: lookalikeTagsInGroup
                };
            }
        });

        return grouped;
    };

    // Render the combined characters by group and highlight discrepancies
    const renderGroup = (group, tags, colorStyle) => (
        <div key={group} className="character-group">
            <b style={{textTransform: 'uppercase'}}>{group}</b>
            <p>
                {tags.length > 1 ? (tags.map((tag, index) => (
                    <span key={index} style={tag.highlight ? colorStyle : {}}>
                        {tag.location} {tag.frequency !== 'always' && tag.frequency} {tag.value}{index < tags.length - 1 && ', '}
                    </span>
                ))) : (
                    <span style={colorStyle}>-----------------</span>
                )}
            </p>
        </div>
    );

    const colorStyles = {
        missing: { color: 'darkgreen', fontWeight: 'bold' },
        extra: { color: 'darkred', fontWeight: 'bold' }
    };

    // Combine characters for both the main taxon and the lookalike taxon by group
    const combinedGroups = combineAndHighlightByGroup(taxon.characters || {}, lookalike.characters || {});

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="lookalike-modal">
            <Row>
                <Col xs="12" md="6">
                    <Card style={{height: '100%'}}>
                        <CardHeader>
                            <CardTitle tag="h4" className="impactful" style={{textAlign: 'center'}}>{taxon.taxon_name}</CardTitle>
                        </CardHeader>
                        <div className="taxon-result-img-container">
                            {taxon.photo_img ? (
                                <>
                                <CardImg className="taxon-result-img" src={taxon.photo_img} alt={taxon.taxon_name} />
                                </>
                            ) : (
                                <CardImg className="taxon-result-img" src={SimpleMyxo} alt="Placeholder that would otherwise display this card's taxon." style={{filter: `invert(0.9) hue-rotate(220deg)`, backgroundColor: '#eee', padding: '10%'}}/>
                            )}
                        </div>
                        <CardBody>
                            {Object.keys(combinedGroups).length > 0 ? (
                                Object.entries(combinedGroups).map(([group, { mainTags }]) =>
                                    renderGroup(group, mainTags, colorStyles.missing)
                                )
                            ) : (
                                <p>No missing characters</p>
                            )}
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="12" md="6">
                    <Card style={{height: '100%'}}>
                        <CardHeader>
                            <CardTitle tag="h4" className="impactful" style={{textAlign: 'center'}}>{lookalike.taxon_name}</CardTitle>
                        </CardHeader>
                        <div className="taxon-result-img-container">
                            {lookalike.photo_img ? (
                                <>
                                <CardImg className="taxon-result-img" src={lookalike.photo_img} alt={lookalike.taxon_name} />
                                </>
                            ) : (
                                <CardImg className="taxon-result-img" src={SimpleMyxo} alt="Placeholder that would otherwise display this card's taxon." style={{filter: `invert(0.9) hue-rotate(220deg)`, backgroundColor: '#eee', padding: '10%'}}/>
                            )}
                        </div>
                        <CardBody>
                            {Object.keys(combinedGroups).length > 0 ? (
                                Object.entries(combinedGroups).map(([group, { lookalikeTags }]) =>
                                    renderGroup(group, lookalikeTags, colorStyles.extra)
                                )
                            ) : (
                                <p>No extra characters</p>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Modal>
    );
};

export default LookalikeModal;
