import React, { useState } from 'react';
import { Col, Card, CardBody, CardFooter, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Label, CardTitle, CardHeader, FormGroup } from 'reactstrap';

const FilterMenu = ({filtersOpen, onApplyFilters, wide}) => {
  const [taxonRankOpen, setTaxonRankOpen] = useState(false);
  const [sortOrderOpen, setSortOrderOpen] = useState(false);
  const [taxonRank, setTaxonRank] = useState('');
  const [sortOrder, setSortOrder] = useState('ascending');
  const [tagsValue, setTagsValue] = useState('');
  const [hasSubtaxaChecked, setHasSubtaxaChecked] = useState(false);

  const toggleTaxonRank = () => setTaxonRankOpen(!taxonRankOpen);
  const toggleSortOrder = () => setSortOrderOpen(!sortOrderOpen);

  const getTaxonRankDisplay = () => {
    switch (taxonRank) {
      case 'genus': return 'Genus';
      case 'species': return 'Species';
      case 'family': return 'Family';
      case 'order': return 'Order';
      default: return 'Any Rank';  // Display 'Any Rank' when value is ''
    }
  };

  const getSortOrderDisplay = () => {
    return sortOrder === 'ascending' ? 'Alphabetical' : 'Reverse Alphabetical';
  };

  const handleApplyFilters = () => {
    onApplyFilters(undefined, 1, taxonRank === 'Select Taxon Rank' ? '' : taxonRank, sortOrder, tagsValue, hasSubtaxaChecked)
  };

  const handleClearFilters = () => {
    setTaxonRank('');
    setSortOrder('ascending');
    setTagsValue('');
    
    onApplyFilters(undefined, 1, '', 'ascending', '')
  };

  console.log('filters received: ', filtersOpen)

  //className={`filter-menu ${filtersOpen ? 'open' : ''}`}
  return (
    <Card className={`search-card-sidebar${filtersOpen ? '' : ' closed'}${wide ? ' wide-sidebar' : ' small-sidebar'}`}>
        <CardHeader>
            <CardTitle tag="h4" className="impactful" style={{marginBottom: 0}}>
                Filter Taxa
            </CardTitle>
        </CardHeader>
        <CardBody>
            {/* Tag Value Search */}
            <Label>Tag values (unimplemented)</Label>
            <Input
                type="text"
                className="search-bar-input"
                placeholder="Search by tag value..."
                value={tagsValue}
                onChange={e => setTagsValue(e.target.value)}
                style={{maxWidth: '400px'}}
            />
            <div className="search-filter-btn-container">
                {/* Taxon Rank Dropdown */}
                <div style={{marginTop: '20px'}}>
                    <Label>Species rank</Label>
                    <Dropdown isOpen={taxonRankOpen} toggle={toggleTaxonRank}>
                        <DropdownToggle caret style={{textTransform: 'capitalize', margin: 0}}>
                            {getTaxonRankDisplay()}
                        </DropdownToggle>
                        <DropdownMenu>
                        <DropdownItem onClick={() => setTaxonRank('')}>Any Rank</DropdownItem>
                        <DropdownItem onClick={() => setTaxonRank('order')}>Order</DropdownItem>
                        <DropdownItem onClick={() => setTaxonRank('family')}>Family</DropdownItem>
                        <DropdownItem onClick={() => setTaxonRank('genus')}>Genus</DropdownItem>
                        <DropdownItem onClick={() => setTaxonRank('species')}>Species</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
                {/* Sort Order Dropdown */}
                <div style={{marginTop: '20px'}}>
                    <Label>Result order</Label>
                    <Dropdown isOpen={sortOrderOpen} toggle={toggleSortOrder}>
                        <DropdownToggle caret style={{textTransform: 'capitalize', margin: 0}}>
                            {getSortOrderDisplay()}
                        </DropdownToggle>
                        <DropdownMenu>
                        <DropdownItem onClick={() => setSortOrder('ascending')}>Alphabetical</DropdownItem>
                        <DropdownItem onClick={() => setSortOrder('descending')}>Reverse Alphabetical</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
            <FormGroup check inline style={{marginTop: '20px'}}>
              <Input 
                type="checkbox" 
                checked={hasSubtaxaChecked} 
                onChange={e => setHasSubtaxaChecked(e.target.checked)} 
              />
              <Label check>
                  Has subtaxa
              </Label>
            </FormGroup>
        </CardBody>
        <CardFooter className="card-footer-dark" style={{display: 'flex', justifyContent: 'space-between'}}>
            <Button color="info" onClick={handleApplyFilters} style={{width: '150px'}}>
            Apply
            </Button>
            <Button color="danger" className="ml-2" onClick={handleClearFilters} style={{width: '150px'}}>
            Clear Filters
            </Button>
        </CardFooter>
    </Card>
  );
};

export default FilterMenu;