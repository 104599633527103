import React, { Component } from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap';

class AddTaxonPage extends Component {
    state = {
      taxon_name: '',
      taxon_rank: 'species',
      parent_taxon_name: '',
      test_box: true,
      is_error: false,
      message: ''
    };
  
    getLastId = async () => {
      try {
        const res = await axios.get('/api/idtracker');
        return res.data.amount;
      } catch (err) {
        console.log(err);
      }
    };
  
    // TODO: verify this works after change to taxa api endpoint
    getParent = async (name) => {
      try {
        const res = await axios.get(`/api/taxa?taxon_name=${name}&exact=true&scientific_only=true`);
        if (res.data.results && res.data.results.length > 0) {
          return res.data.results[0];
        }
      } catch (err) {
        console.log(err);
      }
    };

    addChild = (parent_id, child_id) => {
      const parent_and_child = {parent_id, child_id}
      axios
        .put('/api/taxa/addchild', parent_and_child)
        .catch((err) => console.log(err));
    };
  
    addTaxon = async () => {
      let taxon_made = false
      var next_id = (await this.getLastId()) + 1;

      try {
        if (this.state.taxon_name && this.state.parent_taxon_name) {    
          // first get parent
          const parent_taxon = await this.getParent(this.state.parent_taxon_name)

          // set document fields
          const new_taxon = { taxon_id: next_id, parent_id: parent_taxon.taxon_id, 
            taxon_rank: this.state.taxon_rank, taxon_name: this.state.taxon_name, subtaxon_ids: [], created_at: new Date(), characters: {}, parent_name: parent_taxon.taxon_name, };

          // then add new taxon and description
          console.log(new_taxon)
          await axios.post('/api/taxa', new_taxon);
          taxon_made = true;
          // and finally add to children
          this.addChild(parent_taxon.taxon_id, next_id)

          this.setState({
            taxon_name: '',
            parent_taxon_name: '',
            message: 'Taxon created successfully!',
            is_error: false
          });
        } else {
          const emptyFields = [];
          const fieldsToCheck = ['taxon_name', 'parent_taxon_name'];

          // iterate over fields, add empty ones in Title Case to array
          for (const field of fieldsToCheck) {
            if (!this.state[field]) {
              emptyFields.push(field.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())); // Convert snake_case to Title Case
            }
          }

          this.setState({ message: `Input field(s) required: ${emptyFields.join(', ')}` || "An unknown error occurred.", is_error: true })
        }
      } catch (error) {
        console.error('An error occurred:', error);
        this.setState({ message: error.message || "An unknown error occurred.", is_error: true })
        // delete new taxon if error occurred after its creation
        if (taxon_made) axios
          .delete('/api/taxa', this.state.taxon_id)
          .catch((err) => console.log(err));
      }
    };
  
    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value,
      });
    };
  
    handleCheckboxChange = (e) => {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    };

  render() {
    let { taxon_name, taxon_rank, parent_taxon_name } = this.state;

    return (
      <>
        <div className="content">
          <Helmet>
            <title>Add Taxon | TaxoKeys</title>
          </Helmet>
          <Row>
            <Col md="12">
              <Card className="central-display">
                <CardHeader>
                  <CardTitle tag="h5"><i className="nc-icon nc-simple-add"></i> Add Taxon to Database </CardTitle>
                </CardHeader>
                <CardBody className="all-icons">
                  <div className="form-container">
                    {this.state.message && (
                      <div className={this.state.is_error ? "alert alert-danger" : "alert alert-success"}>
                        {this.state.message}
                      </div>
                    )}
                    <form className="simple-form">
                      <div className="form-group">
                          <label htmlFor="taxon_name" id="name">Taxon Name:</label>
                          <input type="text" name="taxon_name" id="taxon_name" placeholder="Taxon Name" onChange={this.handleChange} value={taxon_name} />
                      </div>
                      
                      <div className="form-group">
                          <label htmlFor="taxon_rank" id="name">Taxon Rank:</label>
                          <select name="taxon_rank" id="taxon_rank" value={taxon_rank} onChange={this.handleChange}>
                            <option value="class">Class</option>
                            <option value="subclass">Subclass</option>
                            <option value="superorder">Superorder</option>
                            <option value="order">Order</option>
                            <option value="family">Family</option>
                            <option value="genus">Genus</option>
                            <option value="species">Species</option>
                            <option value="subspecies">Subspecies</option>
                          </select>
                      </div>

                      <div className="form-group">
                          <label htmlFor="parent_taxon_name" id="name">Parent Taxon Name:</label>
                          <input type="text" name="parent_taxon_name" id="parent_taxon_name" placeholder="Parent Taxon Name" onChange={this.handleChange} value={parent_taxon_name} />
                      </div>
                      
                      <button type="button" onClick={this.addTaxon}>Add Taxon</button>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  };

};

export default AddTaxonPage;
