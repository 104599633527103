import axios from 'axios';
import TaxonDescription from '../components/TaxoKeysCards/TaxonDescription.js';
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import NotFound from './NotFoundPage.js';
import Loading from '../components/Helpers/Loading.js';
import { Helmet } from 'react-helmet';
import Lookalikes from '../components/TaxoKeysCards/Lookalikes.js';
import Citations from '../components/TaxoKeysCards/Citations.js';
import { useTaxon } from '../util/TaxonContext'; // Import the custom hook

const TaxonDescriptionPage = () => {
    const { taxon, setTaxon } = useTaxon(); // Access taxon and setTaxon from context
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const taxonName = params.get('name');
        const taxonId = params.get('id');

        // Check if taxon data is passed via state and it's not a page reload
        if ((location.state && location.state.taxon)) {
            setTaxon(location.state.taxon);  // Use taxon from state
            setIsLoading(false);
        } else if (taxonName || taxonId) {
            // If taxon data is not passed, make an API request
            axios.get(`/api/taxa?taxon_name=${taxonName || ''}&taxon_ids=${taxonId || ''}&exact=true&scientific_only=true`)
                .then((res) => {
                    if (res.data.results.length !== 0) {
                        setTaxon(res.data.results[0])
                        setIsLoading(false);
                    } else {
                        throw new Error(`No taxon found by name ${taxonName}!`)
                    }
                })
                .catch((error) => {
                    setError(true);
                    setIsLoading(false);
                });
        } else {
            setError(true);
        }
    }, [location]);

    return (
        <div className="content limited-content">
            {error ? (
                <NotFound />
            ) : (
                !isLoading ? (
                    <>
                        <Helmet>
                            <title>{taxon.taxon_name} | TaxoKeys</title>
                        </Helmet>
                        <TaxonDescription isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
                        <Lookalikes />
                        <Citations isEditMode={isEditMode} />
                    </>
                ) : (
                    <Loading /> 
                )
            )}
            
        </div>
    )
}

export default TaxonDescriptionPage;