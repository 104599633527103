import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./AuthContext";

// Wrapper to protect routes
function ProtectedRoute({ element, authRequired }) {
  const { isAuthenticated } = useContext(AuthContext);

  if (authRequired && !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  
  return element;
}

export default ProtectedRoute;