import Spinner from "../../assets/img/taxo-spin.gif";
import Logo from "../../assets/img/LogoBlackSmall.png"

const Loading = () => {
    return (
        <div className="loading">
            Loading...
            <img src={Logo} alt="Loading..." className='logo-spin'></img>
        </div>
    )
}

export default Loading;