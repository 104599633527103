import Dagre from '@dagrejs/dagre';
import { ReactFlow, Handle, MarkerType, BaseEdge, EdgeLabelRenderer, getBezierPath, ReactFlowProvider } from '@xyflow/react';
import TDaddy from "../../assets/img/ultra-secret-admin-info.png";
import '@xyflow/react/dist/style.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import FlowBackground from './FlowBackground';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import KeyModal from '../TaxoKeysCards/KeyModal';
import { TaxonProvider } from '../../util/TaxonContext';

const KeyFlowchart = ({ keyJson }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const reactFlowInstance = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalId, setModalId] = useState(null);

  const toggle = (e, id) => {
    setModalId(id)
    setModalOpen(!modalOpen)
  };

  const onInit = (reactFlow) => {
    reactFlowInstance.current = reactFlow;
  };

  function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const ImageNode = ({ data }) => {
    return (
      <div style={{height: '174px', display: 'flex', alignItems: 'center'}}>
        <Card className="hover-card-grow key-card" style={{ maxWidth: '130px', textAlign: 'center', overflow: 'hidden' }}>
          <div onClick={(e) => toggle(e, data.id)} className="taxon-card-clickable">
            {data.image ? (
              <div style={{ width: 130, height: 130 }}>
                <img className="taxon-result-img" src={data.image} alt="Taxon Node" />
              </div>
            ) : (
              <i className="nc-icon nc-image" style={{ fontSize: 100, marginTop: '10px', color: getRandomColor(), width: '100%', height: 'auto' }}/>
            )}
            <div style={{padding: '10px',}}>
              <b className="key-card-scientific-name" style={{ marginTop: '10px' }}>{data.scientific_name}</b>
              {data.common_name !== '' && (
                <b className="key-card-common-name" style={{ marginTop: '10px' }}>{data.common_name}</b>
              )}
            </div>
          </div>
        </Card>
        <Handle style={{ opacity: 0 }} type="source" position={data.sourcePosition ?? 'right'} />
        <Handle style={{ opacity: 0 }} type="target" position={data.targetPosition ?? 'left'} />
      </div>
    );
  };

  const KeyNode = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
            textAlign: 'center', padding: '10px', border: '1px solid #ddd', borderRadius: 31, 
            backgroundColor: 'white' }}>
            <i className="nc-icon nc-key-25" style={{ fontSize: 40 }}/>

            <Handle style={{opacity: 0}} type="source" position="right" />
            <Handle style={{opacity: 0}} type="target" position="left" />
        </div>
    );
  };

  const QuestionNode = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',
            textAlign: 'center', padding: '10px', border: '1px solid #ddd', borderRadius: 31, 
            backgroundColor: 'white' }}>
            <i className="nc-icon nc-simple-remove" style={{ fontSize: 40, color: 'red' }}/>

            <Handle style={{opacity: 0}} type="source" position="right" />
            <Handle style={{opacity: 0}} type="target" position="left" />
        </div>
    );
  };

  const KeyEdge = ({
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    data
  }) => {
    const [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });

    const formattedEntries = Object.entries(data.characters ?? {}).map(([label, description], index) => (
      <div key={index} style={{}}>
        <div><b>{label}:</b> {description}</div>
      </div>
    ));
  
    return (
      <>
        <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              textAlign: 'left',
            }}
            className="nodrag nopan"
          >
            {/* Background rectangle */}
            <div
              style={{
                backgroundColor: 'white', // White background
                maxWidth: '200px',
                padding: '5px 10px', // Add some padding
                borderRadius: '5px', // Rounded corners
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)', // Optional shadow for better visibility
              }}
            >
              {formattedEntries}
            </div>
          </div>
        </EdgeLabelRenderer>
      </>
    );
  };

  const nodeTypes = useMemo(
    () => ({
      TTNode: ImageNode,
      KNode: KeyNode,
      QNode: QuestionNode,
    }), []
  );

  const edgeTypes = useMemo(
    () => ({
      KEdge: KeyEdge
    }),
    []
  )

  const createIdMap = (keyArray) => {
    const idMap = {};
    keyArray.forEach(entry => {
      idMap[entry.id] = entry;
    });
    return idMap;
  };

  function addParentIdToNodes(nodeMap) {
    Object.keys(nodeMap).forEach(nodeId => {
      const node = nodeMap[nodeId];
      if (node.children && node.children.length > 0) {
        node.children.forEach(childId => {
          if (nodeMap[childId]) {
            nodeMap[childId].parentId = nodeId;
          }
        });
      }
    });
    return nodeMap;
  }

  const insertNode = (node, nodes, edges, idMap) => {
    const edgeStyle = { strokeWidth: 2, stroke: '#AAA' }
    const markerEndStyle = { type: MarkerType.ArrowClosed, color: '#AAA', width: 15, height: 15 };

    const taxonChildren = node.children.filter(childId => childId.startsWith('Taxon'));
    const nonTaxonChildren = node.children.filter(childId => !childId.startsWith('Taxon'));

    if (node.type === 'TTNode') {
      nodes.push({
        id: node.id,
        type: 'TTNode',
        data: { scientific_name: node.name, common_name: '', image: node.photo_url, id: node.id.slice(5) },
        draggable: false,
      });
      if (node.children && node.children.every(childId => childId.startsWith('Taxon'))) {
        for (let childId of node.children) {
            edges.push({
                id: node.id + '' + childId,
                source: node.id,
                target: childId,
                markerEnd: markerEndStyle,
                style: edgeStyle,
                animated: true,
                selectable: false,
            });
        }
      }
    } else if (node.type === 'KNode') {
      // If there are non-taxon children, add the KNode and connect it to its parent
      if (nonTaxonChildren.length > 0) {
        nodes.push({
          id: node.id,
          type: 'KNode',
          draggable: false,
        });

        if (idMap[node.parentId] && idMap[node.parentId].type === 'TTNode') {
          edges.push({
            id: node.parentId + '' + node.id,
            source: node.parentId,
            target: node.id,
            type: 'KEdge',
            data: { characters: node.characters },
            markerEnd: markerEndStyle,
            style: edgeStyle,
            animated: true,
            selectable: false,
          });
        }

        // Connect KNode to its non-taxon children
        for (let childId of nonTaxonChildren) {
          console.log(`Connecting ${node.id} to ${childId}`)
          edges.push({
            id: node.id + '' + childId,
            source: node.id,
            target: childId,
            type: 'KEdge',
            data: { characters: idMap[childId].characters }, // You might want to include or exclude this
            markerEnd: markerEndStyle,
            style: edgeStyle,
            animated: true,
            selectable: false,
          });
        }
      }
      else if (taxonChildren.length === 1) {
        // If there's only one taxon child, connect it directly with the edge data
        edges.push({
          id: node.parentId + '' + taxonChildren[0],
          source: node.parentId,
          target: taxonChildren[0],
          type: 'KEdge',
          data: { characters: node.characters },
          markerEnd: markerEndStyle,
          style: edgeStyle,
          animated: true,
          selectable: false,
        });
      } else if (taxonChildren.length > 1) {
        // If multiple taxon children, add a QNode
        const qnodeId = node.id + '_QNode';
        nodes.push({
          id: qnodeId,
          type: 'QNode',
          draggable: false,
        });

        // Connect parent to QNode with edge data
        edges.push({
          id: node.parentId + '' + qnodeId,
          source: node.parentId,
          target: qnodeId,
          type: 'KEdge',
          data: { characters: node.characters },
          markerEnd: markerEndStyle,
          style: edgeStyle,
          animated: true,
          selectable: false,
        });

        // Connect QNode to each taxon child
        for (let childId of taxonChildren) {
          edges.push({
            id: qnodeId + '' + childId,
            source: qnodeId,
            target: childId,
            markerEnd: markerEndStyle,
            style: edgeStyle,
            animated: true,
            selectable: false,
          });
        }
      }
    } else {
      console.error('Unknown node type "' + node.type + '" returned.');
    }
  }

  useEffect(() => {
    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
    g.setDefaultEdgeLabel(() => ({}));

    const getLayoutedElements = (nodes, edges, options) => {
      g.setGraph({
        rankdir: options.direction,
        nodesep: '100',
        ranksep: '500',
        ranker: 'tight-tree'
      });

      if (!nodes || !edges) return { nodes: [], edges: [] };

      const defaultDimensions = {
        TTNode: { width: 130, height: 174 },
        KNode: { width: 62, height: 174 },
        QNode: { width: 62, height: 174 },
      };

      edges.forEach((edge) => g.setEdge(edge.source, edge.target));
      nodes.forEach((node) =>
        g.setNode(node.id, {
          ...node,
          width: node.measured?.width ?? defaultDimensions[node.type]?.width ?? 100,
          height: node.measured?.height ?? defaultDimensions[node.type]?.height ?? 100,
        })
      );

      Dagre.layout(g);

      const leftmostNode = nodes.reduce((minNode, node) => {
        const position = g.node(node.id);
        return position.x < g.node(minNode.id).x ? node : minNode;
      }, nodes[0]);

      const leftmostY = g.node(leftmostNode.id).y;

      const viewportHeight = window.innerHeight;
      const verticalCenter = viewportHeight / 3 - 20;

      const yOffset = verticalCenter - leftmostY;

      return {
        nodes: nodes.map((node) => {
          const position = g.node(node.id);
          const nodeWidth = node.measured?.width ?? defaultDimensions[node.type]?.width ?? 100;
          // TODO: also correct x upon rerender
          const x = position.x - nodeWidth / 2 + 40;
          const y = position.y + yOffset;

          return { ...node, position: { x, y } };
        }),
        edges,
      };
    };

    if (keyJson) {
      const nodes = [];
      const edges = [];

      // Necessary due to JSON being returned in string format
      const parsedKeyJson = keyJson.map(entry => JSON.parse(entry));
      console.log(parsedKeyJson);
      const idMap = createIdMap(parsedKeyJson);
      const parentedIdMap = addParentIdToNodes(idMap);

      for (let nodeId in parentedIdMap) {
        const node = parentedIdMap[nodeId];

        insertNode(node, nodes, edges, idMap);
      }

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges, { direction: 'LR' });

      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    }
  }, [keyJson]);

  return (
    nodes.length > 0 && edges.length > 0 && (
      <div style={{flexGrow: 1}}>
        <ReactFlowProvider>
          <ReactFlow
            style={{ border: 0, borderRadius: '12px'}}
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onInit={onInit} // Attach the onInit handler
            minZoom={0.1}
            maxZoom={1.5}
            defaultViewport={{ x: 0, y: 0, zoom: 0.5 }} // Adjust the zoom level here
          >
            <FlowBackground backgroundColor='#fdfdfd'/>
          </ReactFlow>
        </ReactFlowProvider>
        <TaxonProvider>
          <KeyModal taxon_id={modalId} isOpen={modalOpen} toggle={toggle} />
        </TaxonProvider>
      </div>
    )
  );
};

export default KeyFlowchart;
