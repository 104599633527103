import React, { useContext, useState } from "react";
import Helmet from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, CardHeader, CardBody, CardTitle, Row, Col} from 'reactstrap';
import axios from "axios";
import { AuthContext } from "../util/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const usernameRef = React.createRef();
  const passwordRef = React.createRef();

  const { setIsAuthenticated } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
  
    try {
      const { data } = await axios.post(
        "/api/login",
        { username, password },
        { withCredentials: true }
      );
      const { success, message } = data;
      if (success) {
        setMessage(message);
        setIsError(false);
        setIsAuthenticated(true); // Update authentication state
  
        const searchParams = new URLSearchParams(location.search);
        const return_location = "/" + (searchParams.get('return_page') || "");
  
        navigate(return_location || "/"); // Navigate to the intended location
      } else {
        setMessage(message);
        setIsError(true);
      }
    } catch (error) {
      console.log(error);
      setMessage("Invalid username or password. Please try again.");
      setIsError(true);
    }
  };

  return (
    <div className="content login-content">
      <Helmet>
        <title>Login | TaxoKeys</title>
      </Helmet>
      <Row>
        <Col md="6" className="offset-md-3">
          <Card className="login-card">
            <CardHeader>
              <CardTitle tag="h5" className="text-center">Log in to Curator Account</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="form-container">
                {message && (
                  <div className={isError ? "alert alert-danger" : "alert alert-success"}>
                    {message}
                  </div>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                      type="text"
                      id="username"
                      name="username"
                      autoComplete="username"
                      ref={usernameRef}
                      placeholder="Enter your username"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      autoComplete="current-password"
                      ref={passwordRef}
                      placeholder="Enter your password"
                      className="form-control"
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
