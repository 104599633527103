import { Form, Button } from "reactstrap";
import { CardTitle, FormGroup, Input, Label } from "reactstrap";
import { useTaxon } from "../../util/TaxonContext"; // Import useTaxon hook
import { useEffect } from "react";

export default function Citations({ isEditMode }) {
    const { taxon, setTaxon } = useTaxon(); // Access taxon and setTaxon from context

    const handleCitationChange = (index, newValue) => {
        // Safeguard to ensure we don't access taxon.citations if undefined
        const updatedCitations = [...(taxon.citations || [])];
        updatedCitations[index] = newValue;

        // Update the taxon in context with the new citations
        setTaxon({ ...taxon, citations: updatedCitations });
    };

    const addCitation = (e) => {
        e.preventDefault()

        // Ensure citations is an array before adding to it
        const updatedCitations = [...(taxon.citations ?? []), ''];

        // Update the taxon in context with the new citation
        setTaxon({ ...taxon, citations: updatedCitations });
    };

    useEffect(() => {
        console.log(taxon.citations);
    }, [taxon])
    useEffect(() => {
        console.log('isEditMode changed:', isEditMode);
    }, [isEditMode]);

    return (
        <div>
            {/* Only display the Citations section if we have citations or are in edit mode */}
            {((taxon.citations && taxon.citations.length > 0) || isEditMode) && (
                <>
                    <CardTitle tag="h3" className="impactful section-header lookalikes-header">
                        <div style={{ lineHeight: '1em', marginTop: '2px' }}>Citations</div>
                    </CardTitle>
                    {isEditMode ? (
                        <Form>
                            {(taxon.citations || []).map((citation, index) => (
                                <FormGroup key={index} className="flag-modal-form-group">
                                    <Label for={`citation-${index}`} tag="b">Citation {index + 1}</Label>
                                    <Input
                                        type="textarea"
                                        name={`citation-${index}`}
                                        id={`citation-${index}`}
                                        rows="3"
                                        placeholder="Add citation here..."
                                        value={citation}
                                        onChange={(e) => handleCitationChange(index, e.target.value)}
                                        required
                                    />
                                </FormGroup>
                            ))}
                            <Button color="primary" onClick={(e) => addCitation(e)}>Add Citation</Button>
                        </Form>
                    ) : (
                        <div>
                            {(taxon.citations || []).map((citation, index) => (
                                <p key={index}>{citation}</p>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
