import { createContext, useContext, useState } from 'react';

const TaxonContext = createContext();

export const useTaxon = () => useContext(TaxonContext);

export const TaxonProvider = ({ children }) => {
    const [taxon, setTaxon] = useState({}); // Initialize taxon state

    return (
        <TaxonContext.Provider value={{ taxon, setTaxon }}>
            {children}
        </TaxonContext.Provider>
    );
};
