import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { checkAuthStatus } from './isAuth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Assuming user starts as authenticated
    const [loadingAuthState, setLoadingAuthState] = useState(true);

    useEffect(() => {
        const fetchAuthStatus = async () => {
            try {
                const isAuth = await checkAuthStatus();
                setIsAuthenticated(isAuth);
            } catch (error) {
                console.error('Error checking authentication status:', error);
                setIsAuthenticated(false);
            } finally {
                setLoadingAuthState(false);
            }
        };

        fetchAuthStatus();
    }, []);

    // If still loading, render nothing or a loading indicator
    if (loadingAuthState) {
        return null; // Or a loading spinner component
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};