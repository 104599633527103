import React, { Component, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Loading from '../Helpers/Loading';
import { ListGroup, ListGroupItem } from 'reactstrap';
import ShowCharacterDescription from "../Helpers/ShowCharacterDescription"
import { AuthContext } from '../../util/AuthContext';

const CharacterDescription = ({ group, toggleEditMode, saveChanges }) => {
  const [characters, setCharacters] = useState(null);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    // Actions to perform when props.group_name changes
    setIsLoading(true);
    setError(null);
    setSelectedCharacter(null);
    getGroupCharacters(group);
  }, [group]);

  const getGroupCharacters = (group) => {
    axios.get(`/api/characters?group_name=${group.group_name}`)
      .then((res) => { 
        setCharacters(res.data.results);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      })
    console.log(group)
  };

  const handleCharacterClick = (character) => {
    setSelectedCharacter(character);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className='character-lexicon-group' style={{ height: "100%" }}>
      {isLoading &&
        <Loading className="loading" />
      }
      {characters && (
        <div className='description-container' style={{ flex: 1, display: 'flex', flexDirection: 'column', height: "100%", opacity: isLoading ? 0.25 : 1 }}>
          <div className='character-description-container' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px' }}>
            <div style={{ flex: 1, marginLeft: '9px' }}>
              <h3 style={{ marginBottom: '0', marginTop: '6px' }}>
                {group.group_name.charAt(0).toUpperCase() + group.group_name.slice(1)}
              </h3>
            </div>
            <div>
              {isAuthenticated && (
                <>
                  <button onClick={toggleEditMode} className={isEditMode ? "btn btn-danger" : "btn btn-primary"} style={{ marginLeft: '5px' }}>
                    {isEditMode ? "Cancel Edit" : "Edit Character"}
                  </button>
                  {isEditMode && (
                    <button onClick={saveChanges} className="btn btn-success" style={{ marginLeft: '5px' }}>
                      Save Changes
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <hr style={{ margin: '0' }} />
          <div style={{ display: 'flex', flex: 1 }}>
            <div style={{ flex: '0 0 30%', overflowY: 'auto', margin: '10px' }}>
              <ListGroup style={{ maxHeight: 'calc(100vh - 335px)', overflowY: 'auto' }}>
                {characters.sort((characterA, characterB) => characterA.character_name.localeCompare(characterB.character_name))
                .map((character, index) => (
                  <ListGroupItem
                    key={character.character_name}
                    tag="a"
                    href="#"
                    disabled={isLoading}
                    action
                    onClick={(e) => {
                      e.preventDefault();
                      handleCharacterClick(character);
                    }}
                    className={selectedCharacter === character ? 'active' : ''}
                    style={{ backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#e9ecef' }}
                  >
                    {character.character_name}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
            <div style={{ width: '1px', backgroundColor: 'black', opacity: '0.1', marginBottom: '35px' }}></div>
            <div style={{ flex: 1, display: 'flex' }}>
              <ShowCharacterDescription character={selectedCharacter} isAuthenticated={isAuthenticated} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CharacterDescription;
