// Logout.js
import { useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../util/AuthContext';

const Logout = () => {
    const navigate = useNavigate();
    const { setIsAuthenticated } = useContext(AuthContext);

    useEffect(() => {
        axios.get('/api/logout')
            .then((response) => {
                if (response.data.message === 'Logged out successfully') {
                    setIsAuthenticated(false); // Update the authentication state
                    navigate('/');
                } else {
                    console.error('Unexpected logout response:', response.data);
                }
            })
            .catch((error) => {
                console.error('Logout failed:', error);  // Log error if logout failed
            });
    }, [navigate, setIsAuthenticated]);

    return (
        <div className="content">
            Logging out...
        </div>
    );
};

export default Logout;
