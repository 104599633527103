/*!

=========================================================
* Paper Dashboard React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import HomePage from "./pages/HomePage.js";
import SearchPage from "./pages/SearchTaxaPage.js";
import KeysPage from "./pages/GenerateKeyPage.js";
import AddTaxonPage from "./pages/AddTaxonPage.js";
import CharacterGlossaryPage from "./pages/CharacterGlossaryPage.js";
import LoginPage from "./pages/LoginPage.js";
import LogoutPage from "./pages/LogoutPage.js";
import AboutPage from "./pages/AboutPage.js";
import TaxonDescriptionPage from "./pages/TaxonPage.js";
import Dashboard from "./layouts/Admin.js";
import NotFound from "./pages/NotFoundPage.js";
import ProtectedRoute from "./util/ProtectedRoute.js";
import FlagsPage from "./pages/FlagsPage.js";
import { TaxonProvider } from "./util/TaxonContext.js";

const routes = [
  {
    path: "/",
    element: <Dashboard />,  // Your layout wrapping the routes
    children: [
      {
        path: "",
        name: "Home",
        icon: "nc-icon nc-atom",
        element: <HomePage />,
        renderSidebar: true,
      },
      {
        path: "search",
        name: "Search Taxa",
        icon: "nc-icon nc-zoom-split",
        element: <SearchPage />,
        parent: "search",
        renderSidebar: true,
      },
      {
        path: "add-taxon",
        name: "Add Taxon",
        icon: "nc-icon nc-simple-add",
        element: <ProtectedRoute element={<AddTaxonPage />} authRequired={true} />,
        authRequired: true,
        renderSidebar: true,
      },
      {
        path: "character-lexicon",
        name: "Character Glossary",
        icon: "nc-icon nc-book-bookmark",
        element: <ProtectedRoute element={<CharacterGlossaryPage />} authRequired={true} />,
        authRequired: true,
        renderSidebar: true,
      },
      {
        path: "generate-key",
        name: "Generate Key",
        icon: "nc-icon nc-key-25",
        element: <KeysPage />,
        renderSidebar: true,
      },
      {
        path: "taxon-description",
        name: "Taxon Description",
        element: (
          <TaxonProvider>
            <TaxonDescriptionPage />
          </TaxonProvider>
        ),
        parent: "search",
      },
      {
        path: "flags",
        name: "Flags",
        authRequired: true,
        element: <ProtectedRoute element={<FlagsPage />} authRequired={true} />,
      },
      {
        path: "about",
        name: "About",
        icon: "nc-icon nc-alert-circle-i",
        element: <AboutPage />,
        pro: true,
        renderSidebar: true,
      },
      {
        path: "login",
        name: "Login",
        icon: "nc-icon nc-single-02",
        element: <LoginPage />,
      },
      {
        path: "logout",
        name: "Logout",
        icon: "nc-icon nc-single-02",
        element: <LogoutPage />,
      },
      {
        path: "*",  // Wildcard route for any undefined path
        element: <NotFound />,  // Render NotFound page inside Dashboard
      }
    ],
  },
]

export default routes;