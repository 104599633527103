import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Input, Button, InputGroup, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import TaxonResultCard from '../components/TaxoKeysCards/TaxonResultCard.js';
import axios from 'axios';
import Loading from '../components/Helpers/Loading.js';
import FilterMenu from '../components/TaxoKeysCards/FilterMenu.js';

const SearchPage = () => {
  const [allTaxa, setAllTaxa] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(0);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 991);

  // Effect to handle window resizing
  useEffect(() => {
      const handleResize = () => {
          setIsWideScreen(window.innerWidth > 991);
      };

      // Add event listener for resize
      window.addEventListener('resize', handleResize);

      // Clean up the event listener
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const fetchTaxa = async (taxonName = searchTerm, page = currentPage, taxonRank = '', sortOrder = 'ascending', tagsValue = '', hasSubtaxa=false) => {
    setIsLoading(true);
    try {      
      const response = await axios.get('/api/taxa', {
        params: {
          page: page,
          taxon_name: taxonName,
          taxon_rank: taxonRank,
          sort_order: sortOrder,
          tags: tagsValue,
          has_subtaxa: hasSubtaxa,
        }
      });
      setAllTaxa(response.data.results || []);
      setMaxPages(response.data.totalPages);
    } catch (error) {
      console.error("Failed to fetch taxa:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update the URL based on search term and page number
  const updateURL = (term, page) => {
    const params = new URLSearchParams(location.search);
    if (term) {
      params.set('name', term);
    } else {
      params.delete('name');
    }
    if (page) {
      params.set('page', page);
    } else {
      params.delete('page');
    }
    navigate(`?${params.toString()}`);
  };

  const handlePreviousPage = () => {
    const newPage = Math.max(currentPage - 1, 1);
    setCurrentPage(newPage);
    updateURL(searchTerm, newPage);
  };

  const handleNextPage = () => {
    const newPage = Math.min(currentPage + 1, maxPages);
    setCurrentPage(newPage);
    updateURL(searchTerm, newPage);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleFilters = () => {
    console.log(filtersOpen);
    setFiltersOpen(!filtersOpen);
  }

  // Fetch data when the page or search term changes in the URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const name = params.get('name') || '';
    const page = parseInt(params.get('page'), 10) || 1;

    setSearchTerm(name);
    setLastSearch(name);
    setCurrentPage(page);

    fetchTaxa(name, page);
  }, [location.search]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur()
    }
  };

  const handleBlur = (e) => {
    if (searchTerm !== lastSearch) {
      updateURL(searchTerm, 1);
    }
    setLastSearch(searchTerm);
  }

  return (
    <div className="content">
      <Helmet>
        <title>Search Taxa | TaxoKeys</title>
      </Helmet>
      <div className="search-layout">
        <div className="search-left-panel">
          <Card className="search-bar-container">
            <CardHeader>
              <CardTitle tag="h3" className="impactful" style={{marginBottom: 0}}>
                Search Taxa
              </CardTitle>
            </CardHeader>
            <CardBody>
            <div className="search-bar-items">
              <InputGroup className="search-bar-input-group" style={{ border: 'none', flexGrow: 1, marginBottom: 0, backgroundColor: 'white', position: 'relative', overflow: 'visible' }}>
                <Input
                  name="taxon_name"
                  className="search-bar-input"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyPress}
                  onBlur={handleBlur}
                  value={searchTerm}
                  style={{border: 'initial'}}
                />
                {searchTerm ? (
                  <i className="nc-icon nc-simple-remove" onClick={() => {setSearchTerm(''); setLastSearch(''); updateURL('', 1)}} style={{position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: '#bbb', fontSize: '16px' }} />
                ) : (
                  <i className="nc-icon nc-zoom-split" style={{position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: '#bbb', fontSize: '16px' }} />
                )}
              </InputGroup>
              <Button color="warning" style={{margin: '0 0 0 10px', display: 'flex', gap: '5px'}}>
                Search
              </Button>
              <Button onClick={toggleFilters} style={{margin: '0 0 0 10px', display: isWideScreen ? 'none' : 'flex', gap: '5px'}}>
                Filter
                <i className="nc-icon nc-chart-bar-32" />
              </Button>
            </div>
            </CardBody>
          </Card>
          {!isWideScreen && (
            <FilterMenu filtersOpen={filtersOpen} onApplyFilters={fetchTaxa}/>
          )}
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {Object.keys(allTaxa).length > 0 ? (
                <div className="search-results-grid">
                  {allTaxa.map((taxon) => (
                    <TaxonResultCard key={taxon.taxon_name} taxon={taxon} style={{marginBottom: '20px'}}/>
                  ))}
                </div>
              ) : (
                <h5 style={{textAlign: 'center', margin: 0, padding: '0px 10px'}}>No taxa found for current criteria.</h5>
              )}
              <Card className="pagination-card">
                <CardBody className="pagination-button-container">
                  <Button className="btn-secondary" onClick={handlePreviousPage} disabled={currentPage === 1} style={{ width: "12em", margin: 0 }}>
                    Previous Page
                  </Button>
                  <p style={{margin: "0px", textAlign: "center", minWidth: "60px"}}>{allTaxa.length !== 0 ? currentPage : 0} / {maxPages}</p>
                  <Button className="btn-secondary" onClick={handleNextPage} disabled={currentPage >= maxPages} style={{ width: "12em", margin: 0 }}>
                    Next Page
                  </Button>
                </CardBody>
              </Card>
            </>
          )}
        </div>
        {isWideScreen && (
          <FilterMenu filtersOpen={isWideScreen} onApplyFilters={fetchTaxa} wide/>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
