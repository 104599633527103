import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardImg, CardTitle } from "reactstrap";
import SimpleMyxo from "../../assets/img/myxo-placeholder-bic.png";
import LookalikeModal from "./LookalikeModal";
import { useTaxon } from "../../util/TaxonContext";

const Lookalikes = () => {
    const { taxon } = useTaxon(); // Use the taxon object from context
    const [lookalikes, setLookalikes] = useState([]);
    const [selectedLookalike, setSelectedLookalike] = useState(null); // Track the selected lookalike
    const [isModalOpen, setIsModalOpen] = useState(false); // Track modal visibility
    const [prevTaxonId, setPrevTaxonId] = useState(null);
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);
    
    const navigate = useNavigate();  // Initialize useNavigate

    // Effect to handle window resizing
    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 768);
        };

        // Add event listener for resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Check if the taxon has changed (i.e., if the current taxon_id is different from prevTaxonId)
        if (taxon && taxon.taxon_id !== prevTaxonId) {
            setLookalikes([]); // Reset lookalikes while loading new ones
            setPrevTaxonId(taxon.taxon_id); // Update prevTaxonId with the current taxon_id

            if (taxon.characters && Object.keys(taxon.characters).length > 0) {
                axios.post('/api/find-similar', { 
                    characters: taxon.characters, 
                    taxon_name: taxon.taxon_name, 
                    taxon_rank: taxon.taxon_rank 
                })
                    .then(response => {
                        setLookalikes(response.data);
                    })
                    .catch(error => {
                        console.error("Error fetching lookalikes: ", error);
                    });
            }
        }
    }, [taxon, prevTaxonId]);

    const getLookalikePercentage = (lookalike) => {
        // Count tags for extraCharacters and missingCharacters, where each entry has a 'tags' array
        const countTagsWithTagsField = (characterObject) => {
            return Object.values(characterObject).reduce((total, character) => {
                return total + (character.tags ? character.tags.length : 0);
            }, 0);
        };
    
        // Count tags for matchedCharacters, where each entry is directly an array of tags
        const countTagsForMatchedCharacters = (characterObject) => {
            return Object.values(characterObject).reduce((total, tagsArray) => {
                return total + (Array.isArray(tagsArray) ? tagsArray.length : 0);
            }, 0);
        };
    
        // Get total number of matched, extra, and missing tags
        const matchedTagCount = countTagsForMatchedCharacters(lookalike.matchedCharacters);
        const extraTagCount = countTagsWithTagsField(lookalike.extraCharacters);
        const missingTagCount = countTagsWithTagsField(lookalike.missingCharacters);
    
        // Calculate the total number of tags
        const totalTags = matchedTagCount + extraTagCount + missingTagCount;
    
        // Avoid division by zero
        if (totalTags === 0) return 0;
    
        // Calculate and return the percentage of matched tags
        return Math.floor((matchedTagCount / totalTags) * 100);
    };

    const getColorFromPercentage = (percentage) => {
        const red = 200 * (1 - percentage / 100);
        const green = 225 * (percentage / 100);
        const blue = 0;
        
        return `rgb(${red}, ${green}, ${blue})`;
    }

    const renderPercent = (lookalike) => {
        const lookalikePercentage = getLookalikePercentage(lookalike);
        const backgroundColor = getColorFromPercentage(lookalikePercentage);

        return (
            
            <span
                className="taxon-result-rank"
                style={{ backgroundColor: backgroundColor }}
            >
                {lookalikePercentage}%
            </span>
        )
    }

    const handleShowModal = (e, lookalike) => {
        e.stopPropagation();

        setSelectedLookalike(lookalike); // Set the lookalike to show in the modal
        setIsModalOpen(true); // Open the modal
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCardClick = (e, lookalike) => {
        if (e.ctrlKey) {
            window.open(`/taxon-description?name=${lookalike.taxon_name}`)
        } else {
            navigate(`/taxon-description?name=${lookalike.taxon_name}`, { state: { taxon: lookalike } });
        }
    };

    return (
        <>
            {lookalikes.length > 0 && (
                <>
                <CardTitle tag="h3" className="impactful section-header lookalikes-header" >
                    <div style={{ lineHeight: '1em', marginTop: '2px' }}>Taxon Lookalikes</div>
                </CardTitle>
                <div className="lookalikes-grid">
                    {lookalikes.map((lookalike) => (
                        <div key={lookalike.taxon_id} >
                            <Card className="taxon-result-card hover-card-grow taxon-card-lookalike">
                                {lookalike.photo_img && (
                                    <div className="taxon-result-attribution-container">
                                        <div className="taxon-result-attribution-i">i</div>
                                        <div className="taxon-result-attribution-content">
                                            {lookalike.photo_owner && (<p className="taxon-result-attribution-entry">© {lookalike.photo_owner}</p>)}
                                            <p className="taxon-result-attribution-entry">{lookalike.photo_license}</p>
                                            {lookalike.photo_source ? (
                                                <a className="taxon-result-attribution-entry" href={lookalike.photo_source}>source</a>
                                            ) : (
                                                <p className="taxon-result-attribution-entry">no source</p>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div onClick={(e) => handleCardClick(e, lookalike)} className="taxon-card-clickable">
                                    {renderPercent(lookalike)}
                                    <div className="taxon-result-img-container">
                                        {lookalike.photo_img ? (
                                            <CardImg className="taxon-result-img" src={lookalike.photo_img} alt={lookalike.taxon_name} />
                                        ) : (
                                            <CardImg
                                                className="taxon-result-img"
                                                src={SimpleMyxo}
                                                alt="Placeholder that would otherwise display this card's taxon."
                                                style={{ filter: `invert(0.9) hue-rotate(220deg)`, backgroundColor: '#eee', padding: '10%' }}
                                            />
                                        )}
                                    </div>
                                    <CardBody className="taxon-result-card-body">
                                        <div className="taxon-result-header">
                                            <h5 className="taxon-result-scientific-name">{lookalike.taxon_name}</h5>
                                            {lookalike.common_name && (
                                                <h5 className="taxon-result-common-name">({lookalike.common_name})</h5>
                                            )}
                                        </div>
                                    </CardBody>
                                    {/* Button inside the card for wide screens */}
                                    {isWideScreen && (
                                        <div className="lookalike-details-button-container-large card-footer-dark">
                                            <Button onClick={(e) => handleShowModal(e, lookalike)}>Compare Taxon</Button>
                                        </div>
                                    )}
                                </div>
                            </Card>
                            {/* Button outside the card for smaller screens */}
                            {!isWideScreen && (
                                <div className='lookalike-details-button-container-small'>
                                    <Button onClick={(e) => handleShowModal(e, lookalike)}>Compare</Button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                </>
            )}
            {selectedLookalike && (
                <LookalikeModal
                    lookalike={selectedLookalike} // Pass the selected lookalike
                    isOpen={isModalOpen} // Modal open state
                    toggle={toggleModal} // Toggle function
                />
            )}
        </>
    )
}

export default Lookalikes;