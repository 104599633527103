import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import ShowTaxonCharacters from '../Helpers/ShowTaxonCharacters';
import DropdownSearch from '../Helpers/DropdownSearch';
import Loading from '../Helpers/Loading';
import { Button, CardTitle, Table } from 'reactstrap';

import GBIFLogo from "../../assets/img/external-logos/gbif-mark-white-logo.svg"
import iNatLogo from "../../assets/img/external-logos/inat-favicon-white.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-regular-svg-icons"
import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import ReactNotificationAlert from 'react-notification-alert';
import TaxonPhotoController from './TaxonPhotoController';
import { AuthContext } from '../../util/AuthContext';
import FlagModal from './FlagModal';
import { useTaxon } from '../../util/TaxonContext';

const TaxonDescription = ({ isEditMode, setIsEditMode, keyView }) => {
  const { taxon, setTaxon } = useTaxon();
  const [oldTaxon, setOldTaxon] = useState(null);
  const [iNaturalistId, setiNaturalistId] = useState(null);
  const [gbifResponse, setGbifResponse] = useState(null);
  const [groups, setGroups] = useState(null)
  const [subtaxa, setSubtaxa] = useState([])
  const [isWideScreen, setIsWideScreen] = useState(true);

  const [isLoading, setIsLoading] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [flagModalOpen, setFlagModalOpen] = useState(false);

  const [forceUpdateTrigger, setForceUpdateTrigger] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const [iNatFailed, setINatFailed] = useState(false);
  const [GBIFFailed, setGBIFFailed] = useState(false);

  const { isAuthenticated } = useContext(AuthContext);

  const notificationAlert = useRef();
  const navigate = useNavigate();
  
  const toggleFlagModal = () => {
    setFlagModalOpen(!flagModalOpen)
  };

  const notify = (place, message, type) => {
    const options = {
      place: place,
      message: (
        <div>
          {message}
        </div>
      ),
      type: type,
      icon: "nc-icon nc-alert-circle-i",
      autoDismiss: 7,
    };

    if (notificationAlert.current) {
      notificationAlert.current.notificationAlert(options);
    }
  };

  const toggleEditMode = () => {
    if (!isAuthenticated) return;

    if (isEditMode) {
        setTaxon(oldTaxon);
    } else {
        setOldTaxon(taxon);
    }

    setIsEditMode(!isEditMode);
  };

  const handleSubtaxonClick = (e, subtaxon) => {
    if (e.ctrlKey) {
      window.open(`/taxon-description?name=${subtaxon.taxon_name}`)
    } else {
      navigate(`/taxon-description?name=${subtaxon.taxon_name}`, { state: { taxon: subtaxon } });
    }
  };

  // Warn users if they try to leave/reload while isEditMode is active! TODO: make work on React navigate
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isEditMode) {
        // Standard browser alert for unsaved changes
        event.preventDefault();
        event.returnValue = true; // Required for most browsers
      }
    };

    // Attach event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when component unmounts or isEditMode changes
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEditMode]);

  useEffect(() => {
    if (oldTaxon && taxon.taxon_id === oldTaxon.taxon_id) {
        // If the taxon hasn't changed, no need to reset anything
        return;
    }

    // Reset only when a new taxon is loaded
    setSubtaxa([]);
    setiNaturalistId(null);
    setGbifResponse(null);
    setIsEditMode(false);  // Only reset edit mode for a new taxon

    // Fetch new taxon data from external APIs
    axios.get(`https://api.inaturalist.org/v1/taxa?q=${taxon.taxon_name}&rank=${taxon.taxon_rank.toLowerCase()}`)
        .then(response => {
            if (response.data.results.length > 0 && response.data) {
              const matchingResult = response.data.results.find(result => 
                  result.name && result.name.toLowerCase() === taxon.taxon_name.toLowerCase()
              );
              if (matchingResult && matchingResult.id) {
                  setiNaturalistId(matchingResult.id);
              }
            }
        })
        .catch(error => {
            console.error("Error fetching iNaturalist data: ", error);
        });

    axios.get(`https://api.gbif.org/v1/species?name=${taxon.taxon_name}&limit=1&offset=0`)
        .then(response => {
            if (response.data.results.length > 0) {
                setGbifResponse(response.data.results[0]);
            }
        })
        .catch(error => {
            console.error("Error fetching GBIF data: ", error);
        });

    if (taxon.subtaxon_ids && taxon.subtaxon_ids.length > 0) {
        axios.get('/api/taxa', { params: { parent_id: Number(taxon.taxon_id) } })
            .then(response => {
                setSubtaxa(response.data.results);
            })
            .catch(error => {
                console.error(error);
            });
    }

    fetchGroups();  // Fetch the groups based on the taxon

    setOldTaxon(taxon)
  }, [taxon, setIsEditMode]);

  const fetchGroups = () => {
    if (taxon.characters && Object.keys(taxon.characters).length > 0) {
      const all_groups = [...new Set(Object.values(taxon.characters).map(character => character.group.toLowerCase()))];
      
      axios.get('/api/character-groups', { params: { group_names: all_groups.join(',') }})
        .then(response => {
          const groupMapping = response.data.reduce((acc, group) => {
            acc[group.group_name.toLowerCase()] = {
              description: group.description,
              sublocations: group.sublocations // Assuming `locations` is a property in `group`
            };
            return acc;
          }, {});

          setGroups(groupMapping);
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      setGroups([])
    }
  }

  const handleCharacterClick = (selectedCharacter) => {
    if (!isAuthenticated) return;

    selectedCharacter.value = "";

    if (!taxon.characters || !taxon.characters[selectedCharacter.character_name]) {
      setTaxon((prevTaxon) => ({
        ...prevTaxon,
        characters: {
          ...prevTaxon.characters,
          [selectedCharacter.character_name]: selectedCharacter
        }
      }));
    }
  };

  const handleCharacterChange = (newTags, characterToChange) => {
    if (!isAuthenticated) return;
  
    console.log("Updating taxon with new tags:", newTags);
  
    setTaxon((prevTaxon) => ({
      ...prevTaxon,
      characters: {
        ...prevTaxon.characters,
        [characterToChange]: {
          ...prevTaxon.characters[characterToChange],
          tags: newTags  // Update the "tags" field instead of "value"
        }
      }
    }));
  };

  const handleCharacterDelete = (character) => {
    if (!isAuthenticated) return;

    const { [character]: deletedCharacter, ...remainingCharacters } = taxon.characters;

    setTaxon((prevTaxon) => ({
      ...prevTaxon,
      characters: remainingCharacters
    }));
  };

  const saveChanges = async () => {
    if (!isAuthenticated) return;

    try {
      const response = await axios.post('/api/desc', taxon);
      console.log('Changes saved:', response.data);
      setIsEditMode(false);

      // Use navigate to update location.state after saving changes
      navigate({
        pathname: '/taxon-description',  // Current path from useLocation
        search: `?name=${taxon.taxon_name}`,  // Add the query string
      }, {
        state: { taxon },  // Update state with the modified taxon
        replace: true  // Prevent adding to the history stack
      });
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const forceUpdateComponent = () => {
    setForceUpdateTrigger(!forceUpdateTrigger);
  };
  
  // Effect to handle window resizing
  useEffect(() => {
    const handleResize = () => {
        setIsWideScreen(window.innerWidth > 768);
    };

    handleResize();

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(taxon == null)
  }, [taxon])

  const redirectToKeysPage = () => {
    setShouldRedirect(true);
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const abbreviateTaxonName = (taxon_name) => {
    const words = taxon_name.split(' ');
    if (words.length > 1) {
      words[0] = words[0][0] + '.';
    }

    return words.join(' ');
  }
  
  useEffect(() => {
  }, [flagModalOpen])

  if (shouldRedirect) {
    return (
      <Navigate to={`/generate-key?initial_taxon_id=${taxon.taxon_id}&initial_taxon_name=${taxon.taxon_name}`} />
    );
  }

  if (isLoading) {
    return <Loading style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1 }} />;
  }

  const toggleShowAllSubtaxa = () => {
    setShowAll(!showAll);
  };

  // Determine how many subtaxa to display based on the state
  const subtaxaToDisplay = showAll ? subtaxa : subtaxa.slice(0, 5);

  return (
    <div className='taxon-description-container'>
      <ReactNotificationAlert ref={notificationAlert} />
      <FlagModal taxon={taxon} notify={notify} flagModalOpen={flagModalOpen} setFlagModalOpen={setFlagModalOpen} />
      <div className='taxon-description-main'>
        <div className="desc-header-sm">
          <div className="taxon-description-main-header">
            {taxon.parent_name && !keyView && (
              <h6 style={{ marginBottom: 0 }}>
                <Link to={`/taxon-description?name=${taxon.parent_name}`} onClick={forceUpdateComponent}>&#60; {taxon.parent_name}</Link>
              </h6>
            )}
            <CardTitle tag="h3" className="impactful" style={{ marginBottom: '0px', alignItems: 'center', marginTop: 0 }}>
              <div style={{ lineHeight: '1em', marginTop: '2px' }}>{capitalizeFirstLetter(taxon.taxon_name)}</div>
              {taxon.common_name && (
                <div style={{ color: '#66615B', fontSize: '1rem', lineHeight: '1em', marginTop: '5px' }}> {taxon.common_name}</div>
              )}
            </CardTitle>
          </div>
        </div>
        <div className="taxon-description-header-left">
          <TaxonPhotoController taxon={taxon} setTaxon={setTaxon} isEditMode={isEditMode} iNaturalistId={iNaturalistId} />
        </div>
        <div className="taxon-description-main-content">
          <div className="desc-header-md">
            <div className="taxon-description-main-header">
              {taxon.parent_name && !keyView && (
                <h6 style={{ marginBottom: 0 }}>
                  <Link to={`/taxon-description?name=${taxon.parent_name}`} onClick={forceUpdateComponent}>&#60; {taxon.parent_name}</Link>
                </h6>
              )}
              <CardTitle tag="h3" className="impactful" style={{ marginBottom: '0px', alignItems: 'center', marginTop: 0 }}>
                <div style={{ lineHeight: '1em', marginTop: '2px' }}>{capitalizeFirstLetter(taxon.taxon_name)}</div>
                {taxon.common_name && (
                  <div style={{ color: '#66615B', fontSize: '1rem', lineHeight: '1em', marginTop: '5px' }}> {taxon.common_name}</div>
                )}
              </CardTitle>
            </div>
            <div className="curation-button-container">
              <div
                onClick={toggleFlagModal}
                style={{ display: 'inline-block', cursor: 'pointer' }}
                className="flag-button"
              >
                <FontAwesomeIcon size="lg" icon={faFlag} />
              </div>
              <div
                onClick={() => notify('tr', 'History not yet implemented -- check back soon!', 'info')}
                style={{ display: 'inline-block', cursor: 'pointer' }}
                className="history-button"
              >
                <FontAwesomeIcon size="lg" icon={faClockRotateLeft} />
              </div>
            </div>
          </div>
          <div className="taxon-description-main-metadata">
            <Table borderless>
              <tbody>
                <tr>
                  <th className="th-meta">Taxon Rank</th>
                  <td className="td-meta">{capitalizeFirstLetter(taxon.taxon_rank)}</td>
                </tr>
                {taxon.subtaxon_ids && taxon.subtaxon_ids.length > 0 && (
                  <tr>
                    <th className="th-meta">Subtaxa ({taxon.subtaxon_ids.length.toString()})</th>
                    <td className="td-meta">
                      {subtaxa.length !== 0 ? (
                        <>
                          {subtaxaToDisplay.map((subtaxon, index) => (
                            <div key={subtaxon.taxon_name} className="taxon-description-subtaxon-link-container" style={showAll ? {display: 'inline'} : {}}>
                              <div onClick={(e) => handleSubtaxonClick(e, subtaxon)} className="taxon-description-subtaxon-link">
                                {showAll ? subtaxon.taxon_name : abbreviateTaxonName(subtaxon.taxon_name)}
                              </div>
                              {
                                ((index + 1 < (showAll ? subtaxaToDisplay.length : Math.min(5, subtaxaToDisplay.length))) ? ', ' : '') + ' '
                              }
                            </div>
                          ))}
                          {/* Display [view all] or [collapse] depending on the state */}
                          {subtaxa.length > 5 && (
                            <span className="taxon-metadata-collapser">
                              {showAll ? (
                                <span onClick={toggleShowAllSubtaxa} style={{ cursor: 'pointer', color: 'blue' }}>
                                  [collapse]
                                </span>
                              ) : (
                                <>
                                  <span onClick={toggleShowAllSubtaxa} style={{ cursor: 'pointer', color: 'blue' }}>
                                    [view all]
                                  </span>
                                </>
                              )}
                            </span>
                          )}
                        </>
                      ) : '...'}
                    </td>
                  </tr>
                )}
                <tr>
                  <th className="th-meta">Original authorship</th>
                  <td className="td-meta">{gbifResponse === null ? '...' : (gbifResponse.authorship !== '' ? gbifResponse.authorship : 'None')}</td>
                </tr>
                <tr>
                  <th className="th-meta">Basionym</th>
                  <td className="td-meta">{gbifResponse === null ? '...' : (gbifResponse?.basionym ?? 'None')}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          {!keyView && (
            <div className="taxon-description-button-box">
              {taxon.subtaxon_ids.length > 0 && (
                <Button onClick={redirectToKeysPage} className="taxon-description-header-button taxon-description-key-button" color='warning'>
                  Generate Key
                </Button>
              )}
              <Button disabled={iNaturalistId == null} color="success" className="taxon-description-header-button inat-button" as="a" href={`https://www.inaturalist.org/taxa/${iNaturalistId}`}>
                <img src={iNatLogo} alt="iNaturalist -- a community platform for naturalists" className="taxon-description-external-logo" style={{height: '16px', marginRight: '2px'}} />
                iNaturalist
              </Button>
              <Button disabled={gbifResponse == null} color="success" className="taxon-description-header-button gbif-button" as="a" href={`https://www.gbif.org/species/${gbifResponse ? gbifResponse.key : ''}`}>
                <img src={GBIFLogo} alt="GBIF, the Global Biodiversity Information Facility" className="taxon-description-external-logo" style={{height: '22px'}} />
                GBIF
              </Button>
              {isAuthenticated && isWideScreen && (
                <>
                  <Button
                    className="taxon-description-header-button"
                    onClick={toggleEditMode}
                    color={isEditMode ? 'danger' : 'primary'}
                    disabled={groups == null}
                  >
                    {isEditMode ? "Cancel" : "Edit"}
                  </Button>
                  {isEditMode && (
                    <Button className="taxon-description-header-button" onClick={saveChanges} color='success'>
                      Save
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="taxon-characters">
          <CardTitle tag="h3" className="impactful section-header characters-header">
            <div style={{ lineHeight: '1em', marginTop: '2px' }}>Taxon Characters</div>
          </CardTitle>
          <ShowTaxonCharacters
            groups={groups}
            isLoading={isLoading}
            isEditMode={isEditMode}
            onCharacterChange={handleCharacterChange}
            onCharacterDelete={handleCharacterDelete}
          />
          {isEditMode && (
            <div className="add-character-container">
              <p style={{marginBottom: '5px'}}>Add Taxon Character:</p>
              <DropdownSearch
                direction="down"
                onDocumentClick={handleCharacterClick}
                collectionName={"characters"}
                itemLabelKey={"character_name"}
                id="character_name"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TaxonDescription;
