import { Card, CardBody, CardImg } from "reactstrap";
import SimpleMyxo from "../../assets/img/myxo-placeholder-bic.png";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChild } from "@fortawesome/free-solid-svg-icons";

const TaxonResultCard = ({taxon}) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        if (e.ctrlKey) {
            window.open(`/taxon-description?name=${taxon.taxon_name}`)
        } else {
            navigate(`/taxon-description?name=${taxon.taxon_name}`, { state: { taxon } });
        }
    };

    const colorMapping = {
      'species': '#1cf',
      'genus': '#2c1',
      'family': '#f44',
      'order': '#e8f',
    }

    const getRandomDeg = () => {
      return Math.floor(Math.random() * 360) + "deg";
    }
    const degValue = getRandomDeg();

    const uniqueCharacterNames = [...new Set(Object.values(taxon.characters || {}).map(char => char.group))];

    const generateCharacterGroupTags = () => {
        const group_limit = 2;
        const total_groups = uniqueCharacterNames.length;

        if (total_groups > group_limit) {
            return (
                <div className="taxon-result-group-container">
                    <span className="taxon-result-group">+{total_groups - group_limit}</span>
                    {uniqueCharacterNames.slice(0, group_limit).map((group) => (
                        <span className="taxon-result-group">{group.slice(0, 1).toUpperCase() + group.slice(1).toLowerCase()}</span>
                    ))}
                </div>
            )
        } else if (total_groups) {
            return (
                <div className="taxon-result-group-container">
                    {uniqueCharacterNames.map((group) => (
                        <span className="taxon-result-group">{group.slice(0, 1).toUpperCase() + group.slice(1).toLowerCase()}</span>
                    ))}
                </div>
            )
        } else {
            return (
                <div className="taxon-result-group taxon-result-no-groups">
                    No description
                </div>
            )
        }
    }

    const characters_length = Object.keys(taxon.characters || {}).length;

    return (
        <Card className="taxon-result-card hover-card-grow">
            {taxon.photo_img && (
                <div className="taxon-result-attribution-container">
                    <div className="taxon-result-attribution-i">i</div>
                    <div className="taxon-result-attribution-content">
                        {taxon.photo_owner && (<p className="taxon-result-attribution-entry">© {taxon.photo_owner}</p>)}
                        <p className="taxon-result-attribution-entry">{taxon.photo_license}</p>
                        {taxon.photo_source ? (
                            <a className="taxon-result-attribution-entry" href={taxon.photo_source}>source</a>
                        ) : (
                            <p className="taxon-result-attribution-entry">no source</p>
                        )}
                    </div>
                </div>
            )}
            <div onClick={handleClick} className="taxon-card-clickable">
                <div className="taxon-result-img-container">
                    {taxon.photo_img ? (
                        <>
                        <CardImg className="taxon-result-img" src={taxon.photo_img} alt={taxon.taxon_name} />
                        </>
                    ) : (
                        <CardImg className="taxon-result-img" src={SimpleMyxo} alt="Placeholder that would otherwise display this card's taxon." style={{filter: `invert(0.9) hue-rotate(220deg)`, backgroundColor: '#eee', padding: '10%'}}/>
                    )}
                </div>
                <span
                    className="taxon-result-rank"
                    style={{ backgroundColor: colorMapping[taxon.taxon_rank] || '#000',}}
                >
                    {taxon.taxon_rank}
                </span>
                <CardBody className="taxon-result-card-body">
                    <div className="taxon-result-header">
                        <h5 className="taxon-result-scientific-name">{taxon.taxon_name}</h5>
                        {taxon.common_name && (
                            <h5 className="taxon-result-common-name">({taxon.common_name})</h5>
                        )}
                    </div>
                    <div className="taxon-result-footer">
                        {taxon.subtaxon_ids.length > 0 ? (
                            <div className='taxon-result-subtaxa-count'>
                                <FontAwesomeIcon icon={faChild} />
                                <b className="taxon-result-meta-text">{`${taxon.subtaxon_ids.length}`}</b>
                            </div>
                        ) : (<div style={{visibility: 'hidden'}}>.</div>)}
                        {characters_length > 0 && (
                            <div className='taxon-result-character-count'>
                                <b className="taxon-result-meta-text">{`${characters_length}`}</b>
                                <i className="nc-icon nc-tag-content"/>
                            </div>
                        )}
                    </div>
                </CardBody>
            </div>
        </Card>
    )
}

export default TaxonResultCard;